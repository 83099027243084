export const ON_TOMORROW_SPORT_SELECTED = '[APP] ON TOMORROW SPORT SELECTED';
export const SET_TOMORROW_SEARCH = '[APP] SET TOMORROW SEARCH';
export const ON_TOMORROW_SEARCH = '[APP] ON TOMORROW SEARCH';
export const SET_TOMORROW_SEARCH_STARTED = '[APP] SET TOMORROW SEARCH STARTED';
export const ON_TOMORROW_MAIN_MARKET_SELECTED = '[APP] ON TOMORROW MAIN MARKET SELECTED';
export const ON_TOMORROW_REMOVE_LEAGUE = '[APP] ON TOMORROW REMOVE LEAGUE';
export const SET_TOMORROW_SPORTS = '[APP] SET TOMORROW SPORTS';
export const SET_TOMORROW_NOEVENT = '[APP} SET TOMORROW NO EVENT';
export const SET_TOMORROW_SPORT_EVENTS = '[APP] SET TOMORROW SPORT EVENTS';
export const SET_TOMORROW_SPORT_LOCATIONS = '[APP] SET TOMORROW SPORT LOCATIONS';
export const SET_TOMORROW_SPORT_LOCATIONS_LOADING = '[APP] SET TOMORROW SPORT LOCATIONS LOADING';
export const SET_TOMORROW_LOCATION_EVENTS = '[APP] SET TOMORROW LOCATION EVENTS';
export const SET_TOMORROW_SELECTED_LOCATIONS = '[APP] SET TOMORROW SELECTED LOCATIONS';
export const CLEAR_TOMORROW_LOCATIONS = '[APP] CLEAR TOMORROW LOCATIONS';
export const CLEAR_TOMORROW_SPORT_EVENTS = '[APP] CLEAR TOMORROW SPORT EVENTS';
export const ON_TOMORROW_NO_SEARCH_RESULTS = '[APP] ON TOMORROW NO SEARCH RESULTS';
export const SET_TOMORROW_SEARCH_LOAD_MORE = '[APP] SET TOMORROW SEARCH LOAD MORE';
export const ON_CLEAR_TOMORROW_SEARCH = '[APP] ON CLEAR TOMORROW SEARCH';
export const ON_TOMORROW_RESET_PAGE = '[APP] ON TOMORROW RESET PAGE';
export const UPDATE_TOMORROW_EVENTS_MARKET = '[APP] UPDATE TOMORROW EVENTS MARKET';
export const UPDATE_TOMORROW_EVENTS_STATUS = '[APP] UPDATE TOMORROW EVENTS STATUS';
export const SEARCH_TOMORROW_EVENTS = '[APP] SEARCH TOMORROW EVENTS';
export const SET_TOMORROW_EVENTS_MARKETS = '[APP] SET TOMORROW EVENTS MARKETS';
