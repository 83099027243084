export const it = {
  en: 'EN',
  tr: 'TR',
  fr: 'FR',
  de: 'DE',
  ru: 'RU',
  nl: 'NL',
  it: 'IT',
  sports: 'Sport',
  sportsBook: 'Scommesse Sportive',
  darkMode: 'Modalità oscura',
  lightMode: 'Modalità luce',
  providers: 'Fornitori',
  mostPlayed: 'Il più giocato',
  newGames: 'Nuovi giochi',
  play: 'Gioca',
  enterKeywords: 'Inserisci le parole chiave per cercare i giochi',
  live: 'live',
  inplay: 'Live',
  today: 'Oggi',
  upcoming: 'IN ARRIVO',
  tomorrow: 'Domani',
  highlights: 'Highlights',
  results: 'Risultati',
  lastMinute: 'Ultimo minuto',
  liveCasino: 'Live casino',
  virtualSports: 'Sport virtuali',
  casino: 'Casinò',
  slot: 'Slot',
  gameName: 'Game Name',
  myBets: 'Mie scommesse',
  deposit: 'Deposito',
  home: 'Home',
  login: 'Accesso',
  signup: 'Iscriviti',
  betslip: 'Schedina',
  search: 'Ricerca',
  searchHere: 'Cerca qui',
  transaction: 'Transazione',
  changePassword: 'Modifica password',
  referral: 'Referenza',
  logout: 'Disconnessione',
  single: 'Singolo',
  multiple: 'Multiplo',
  possibleWin: 'Possibile vincita',
  amount: 'Importo',
  betAmount: 'Importo della scommessa',
  ok: 'Ok',
  totalOdds: 'Quote totali',
  odd: 'Quota ',
  totalPossibleWin: 'Vincita totale possibile',
  placeBet: 'Piazza la scommessa',
  betPlaced: 'Scommessa piazzata',
  bet: 'Scommessa',
  fastBet: 'Scommessa veloce',
  fastBet1: 'Scommessa veloce1',
  fastBet2: 'Scommessa veloce2',
  username: 'Nome utente',
  password: 'Password',
  passwordAgain: 'Conferma password',
  stayLoggedIn: 'Rimani connesso',
  all: 'Tutti',
  mainBlanace: 'Saldo principale',
  'in game': 'In gioco',
  won: 'Vinto',
  lost: 'Perso',
  refund: 'Rimborso',
  date: 'Data',
  winAmount: 'Importo vincita',
  stake: 'Puntata',
  pick: 'Scegli',
  myAccount: 'Mio account',
  football: 'Calcio',
  basketball: 'Pallacanestro',
  iceHockey: 'Hockey su ghiaccio',
  tennis: 'Tennis',
  volleyball: 'Pallavolo',
  boxing: 'Pugilato',
  rugby: 'Rugby',
  MMA: 'MMA',
  darts: 'Dardi',
  formula1: 'Formula 1',
  formulaE: 'Formula E',
  special: 'Speciale',
  americanFootball: 'Football americano',
  baseball: 'Baseball',
  hockey: 'Hockey',
  tableTennis: 'Tennis da tavolo',
  badminton: 'Badminton',
  cricket: 'Cricket',
  futsal: 'Calcio a 5',
  golf: 'Golf',
  handball: 'Pallamano',
  rugbyLeagues: 'Campionati di rugby',
  pickCanNotBeCombined: 'Questo prelievo non è cumulabile',
  matchStopped: 'Partita interrotta',
  favorites: 'Preferiti',
  cashout: 'Cashout',
  noCashout: 'No caschout',
  'in progress': 'In corso',
  rejected: 'Rifiutato',
  tip: 'Consiglio',
  noGoal: 'No Goal',
  yes: 'Si',
  over: 'Oltre',
  under: 'Under',
  even: 'Even',
  areYouSureWantCashout: 'Sei sicuro di voler incassare?',
  areYouSureWantPayTicket: 'Sicuro di voler pagato il biglietto?',
  no: 'No',
  bonus: 'Bonus',
  termsAndConditon: 'Termini e condizioni',
  refText1: 'Segnalateci e guadagnate un bonus per ogni riferimento.',
  refText2:
    'Segnala questo codice ad un utente se si iscrive con il proprio codice di riferimento, otterrete',
  refText3: 'bonus % sul vostro conto.',
  refText4: 'EUR sul suo conto.',
  refHeading: 'Codice di riferimento',
  refCopyText: 'Copiare il codice di riferimento',
  refLabel: 'Riferimento',
  credit: 'Credito',
  debit: 'Debito',
  pay: 'Pagamento',
  withdraw: 'Prelievo',
  totalOdd: 'Quota totale',
  totalStake: 'Puntata totale',
  topGames: 'Top Giochi',
  allGames: 'Tutti i giochi',
  canNotBeCombined: 'Questo Pick non è cumulabile',
  betSettled: 'Scommessa conclusa',
  betSuspended: 'Scommessa sospesa',
  insufficientBalance: 'Saldo insufficiente',
  oldPassword: 'Vecchia password',
  newPassword: 'Nuova password',
  confirmPassword: 'Conferma nuova password',
  enterAmount: 'Inserire l\'importo',
  qrScan:
    'Attenzione: il QRCode scade tra 5 minuti. Se necessario, generare un altro QRCode per il pagamento.',
  change: 'Cambio',
  continue: 'Continuare',
  fromDate: 'Dalla data',
  toDate: 'Alla data',
  activityType: 'Tipo di attività',
  show: 'Mostra',
  gameType: 'Tipo di gioco',
  repeatBet: 'Ripetere la scommessa',
  someThingWrong: 'Qualcosa è andato storto',
  status: 'Status',
  coupon: 'ID coupon',
  acceptOddChangesText:
    'È necessario accettare le modifiche per poter piazzare una scommessa.',
  turnOffAway: 'Spegnere comunque',
  acceptOddChanges: 'Accetta modifche quote',
  maxWinAmount: 'Massima vincita',
  maxOdd: 'Quota massima',
  dateTime: 'Data/Ora',
  type: 'Tipo',
  refreshCashout: 'Aggiorna l\'incasso',
  vendor: 'Venditore',
  stakeLowerThen1: 'La puntata deve essere inferiore a',
  stakeGreaterThen1: 'La puntata deve essere maggiore di',
  nothingFound: 'Non è stato trovato nulla',
  casinoGames: 'Giochi di casinò',
  payout: 'Pagamento',
  missingTip: 'Manca il suggerimento',
  pleaseAddAtleast: 'Si prega di aggiungere almeno',
  moreEvents: 'Più eventi',
  bonusType: 'Tipo di bonus',
  totalWin: 'Vincita totale',
  print: 'Stampa',
  noMoreResultFound: 'Nessun risultato trovato',
  noData: 'Nessun dato',
  betSlipIsEmpty: 'La schedina è vuota',
  betSlipIsEmptyDesc: 'Aggiungi una selezione per piazzare una scommessa',
  liveTracker: 'Diretta',
  loggedInAnotherDevice:
    'L\'utente ha già effettuato l\'accesso su un altro dispositivo',
  comingSoon: 'Prossimamente',
  cashoutSuccess: 'Incasso riuscito',
  'in progress': 'In Progress',
  rejected: 'Rejected',
  tip: 'Tip',
  noGoal: 'No Goal',
  yes: 'Yes',
  over: 'Over',
  under: 'Under',
  even: 'Even',
  areYouSureWantCashout: 'Are you sure you want to Cashout?',
  areYouSureWantPayTicket: 'Are you sure you want to Cashout?',
  no: 'No',
  bonus: 'Bonus',
  termsAndConditon: 'Terms and Conditions',
  refText1: 'Refer and earn bonus for each referral.',
  refText2:
    'If you refer this code to any user & user signup with your referral code then you will get ',
  refText3: '  % bonus in your account.',
  refText4: '  EUR in his account.',
  refHeading: ' Referral Code',
  refCopyText: ' Copy Referral Code ',
  refLabel: 'Referral',
  // new translation below
  credit: 'Credit',
  debit: 'Debit',
  pay: 'Pay',
  withdraw: 'Withdraw',
  totalOdd: 'Total Odd',
  totalStake: 'Total Stake',
  topGames: 'Top Games',
  allGames: 'All Games',
  canNotBeCombined: 'This Pick can not be combined',
  betSettled: 'Bet Settled',
  betSuspended: 'Bet Suspended',
  insufficientBalance: 'Insufficient balance',
  oldPassword: 'Old Password',
  newPassword: 'New Password',
  confirmPassword: 'Confirm New Password',
  enterAmount: 'Enter Amount',
  qrScan:
    'Attention: the QRCode expires in 5 minutes. If needed, please generate another QRCode for payment.',
  change: 'Change',
  continue: 'Continue',
  fromDate: 'From Date',
  toDate: 'To Date',
  activityType: 'Activity Type',
  show: 'Show',
  gameType: 'Game Type',
  repeatBet: 'Repeat Bet',
  someThingWrong: 'Something Went Wrong',
  status: 'Status',
  coupon: 'Coupon ID',
  acceptOddChangesText: 'You need to accept changes to be able to place a bet',
  turnOffAway: 'Turn off anyway',
  acceptOddChanges: 'Accept odd changes',
  maxWinAmount: 'Max win amount',
  maxOdd: 'Max odd',
  dateTime: 'Date/Time',
  type: 'Type',
  refreshCashout: 'Refresh Cashout',
  vendor: 'Vendor',
  stakeLowerThen1: 'The stake must be less then',
  stakeGreaterThen1: 'The stake must be greater then',
  nothingFound: 'Nothing Found',
  casinoGames: 'Casino Games',
  payout: 'Payout',
  missingTip: 'Missing Tip',
  pleaseAddAtleast: 'Please add atleast',
  moreEvents: 'more events',
  bonusType: 'Bonus Type',
  totalWin: 'Total Win',
  print: 'Print',
  register: 'Register',
  forgotPassword: 'Dimenticato Password',
  aboutUs: 'Circa noi',
  profile: 'My Profile',
  promotion: 'Promozioni',
  currency: 'Currency',
  address: 'Address',
  responsibleGambling: 'Gioco d\'azzardo responsabile',
  contactUs: 'Contattaci',
  welcome_bonus: 'Bonus di benvenuto',
  deposit_bonus: 'Bonus deposito',
  bet_bonus: 'Bonus scommessa',
  loyalty_bonus: 'Bonus fedeltà',
  losing_bonus: 'Bonus perdente',
  // new Translation end
  marketNames: {
    1: '1x2',
    3: 'Prossimo Goal',
    4: 'Partita rimanente',
    7: 'Doppia chance',
    13: 'Handicap europeo',
    17: 'Entrambe le squadre a segno',
    28: 'Under/Over, inclusi supplementari',
    52: 'Pareggio No Bet',
    59: 'Prossimo Goal',
    226: '12 Compresi supplementari',
    247: '1° tempo rimanente',
    287: 'Partita rimanente',
    5_0: 'Over/Under',
    7_21: '1° tempo Over/Under',
    7_10: 'Vincitore',
    7_37: 'Vincitore (compresi supplementari)',
    7_11: 'Vincitore del set',
    8_39: 'Over/Under (compresi supplementari)',
  },
  tabNames: {
    All: 'Tutti',
    'Main Markets': 'Mercati principali',
    Totals: 'Over/Under',
    '1st Half': '1° tempo',
    '2nd Half': '2° tempo',
    'Home Team': 'Squadra in casa',
    'Away Team': 'Squadra in trasferta',
    'All Periods': 'Tutti i periodi',
    Results: 'Risultati',
    'Home / Away': 'In casa / in trasferta',
    Score: 'Punteggio',
    Corners: 'Angoli',
  },
  oddTranslation: {
    Yes: 'Yes',
    No: 'No',
    Under: 'Under',
    Over: 'Over',
    '1 And Under': '1 + Under',
    'X And Under': 'X + Under',
    '2 And Under': '2 + Under',
    '1 And Over': '1 + Over',
    'X And Over': 'X + Over',
    '2 And Over': '2 + Over',
    '1 And Both Teams To Score': '1 + Yes',
    'X And Both Teams To Score': 'X + Yes',
    '2 And Both Teams To Score': '2 + Yes',
    'Both Teams Not To Score': '+ No',
    'To Nil': '+ No',
    'Both Teams To Score And 1': '1 + Yes',
    'Both Teams To Score And X': 'X + Yes',
    'Both Teams To Score And 2': '2 + Yes',
    Odd: 'Odd',
    Even: 'Even',
    'Or More': '+',
    'Any Other Score': '5+:X/X:5+',
    'No Goal': 'No Goal',
    'Under And Yes': 'Under + Yes',
    'Over And Yes': 'Over + Yes',
    'Under And No': 'Under + No',
    'Over And No': 'Over + No',
    'No Corner': 'No Corner',
    '1st Half': '1st Half',
    '2nd Half': '2nd Half',
  },
  markets: {
    1: '1X2', // Betradar
    2: '1X2',
    18: 'Over/Under', // Betradar
    3: 'Asian Handicap',
    4: 'HT/FT',
    5: 'Odd/Even',
    6: 'Correct Score',
    10: 'Double Chance', // Betradar
    27: 'Double Chance', // Betradar
    9: 'Correct Score 1st Period',
    11: 'Total Corners',
    12: 'Total Fouls Committed',
    13: 'European Handicap',
    16: 'First Team To Score',
    29: 'Both Teams To Score', //Betradar
    19: 'First Card',
    21: 'Under/Over 1st Period',
    22: 'Away Team to Score',
    23: 'Home Team to Score',
    25: 'Double Chance Halftime',
    225: 'Under/Over Including Overtime', //Betradar
    186: 'Winner', //Betradar
    // 29: 'Under/Over Rounds',
    30: 'Under/Over Corners - Home Team',
    31: 'Under/Over Corners - Away Team',
    34: 'Home Team To Score In Both Halves',
    35: 'Away Team To Score In Both Halves',
    41: '1st Period Winner',
    42: '2nd Period Winner',
    43: '3rd Period Winner',
    44: '4th Period Winner',
    45: 'Under/Over 2nd Period',
    46: 'Under/Over 3rd Period',
    47: 'Under/Over 4th Period',
    48: 'Under/Over 5th Period',
    49: '5th Period Winner',
    50: '1X2 Including Overtime',
    51: 'Odd/Even Including Overtime',
    52: '12',
    53: 'Asian Handicap Halftime',
    55: 'First Team To Score 1st Period',
    56: 'Last Team To Score',
    57: 'Last Team To Score Halftime',
    8: 'Next Goal',
    61: 'European Handicap Halftime',
    62: 'Odd/Even Halftime',
    63: '12 Halftime',
    64: 'Asian Handicap 1st Period',
    65: 'Asian Handicap 2nd Period',
    66: 'Asian Handicap 3rd Period',
    67: 'Asian Handicap 4th Period',
    68: 'Asian Handicap 5th Period',
    69: 'Will The Game Go To Overtime?',
    70: 'Highest Scoring Period',
    71: 'Highest Scoring Half',
    72: '1st Period Odd/Even',
    73: '2nd Period Odd/Even',
    74: '3rd Period Odd/Even',
    75: '4th Period Odd/Even',
    76: '5th Period Odd/Even',
    77: 'Under/Over Halftime',
    78: 'To Score Most Sixes',
    79: 'Will A Penalty Be Awarded?',
    80: 'First Corner',
    81: 'Last Corner',
    82: 'Will A Red Card Be Shown?',
    83: 'To Keep a Clean Sheet',
    84: 'To Win Both Halves',
    85: 'To Win From Behind',
    86: 'To Win To Nil',
    88: 'To Qualify',
    89: 'Will The First Try Be Converted?',
    90: 'Will The Match Be Decided In 5th Set?',
    91: 'In What Half First Goal Will Be Scored?',
    92: 'First Team To Score 2nd Half',
    95: 'Corners Handicap',
    96: 'Away Team Win To Nil',
    97: 'Home Team Win To Nil',
    98: 'Home Team To Keep A Clean Sheet',
    99: 'Away Team To Keep A Clean Sheet',
    100: 'Correct Score 2nd Period',
    101: 'Under/Over - Home Team',
    102: 'Under/Over - Away Team',
    104: 'Away Team To Win Both Halves',
    105: 'Home Team To Win Both Halves',
    106: 'Home Team To Win From Behind',
    107: 'Away Team To Win From Behind',
    108: 'Home Team To Score From A Penalty',
    109: 'Away Team To Score From A Penalty',
    110: 'Away Team To Win At Least One Half',
    111: 'Home Team To Win At Least One Half',
    113: 'Both Teams To Score 1st Half',
    114: 'Any Player To Score At Least 2 Goals',
    117: 'Any Player To Score At Least 3 Goals',
    122: 'Team Scoring First To Win The Match',
    123: 'Away Team To Score First And Win The Match',
    124: 'Home Team To Score First And Win The Match',
    128: 'Number of Goals',
    129: 'Under/Over Corners - 1st Half',
    132: 'Under/Over Shots on Target',
    133: 'Under/Over Total Shots',
    134: 'Number of Goals 1st Half',
    136: 'Under/Over Offsides',
    137: 'Home Win No Bet',
    138: 'Away Win No Bet',
    139: 'In Which Half Home Team Will Score First Goal?',
    140: 'In Which Half Away Team Will Score First Goal?',
    143: 'In Which Half Home Team Will Score More Goals?',
    144: 'In Which Half Away Team Will Score More Goals?',
    145: 'Home Team Number Of Goals In 1st Half',
    146: 'Away Team Number Of Goals In 1st Half',
    147: 'Away Team Number Of Goals In 2nd Half',
    148: 'Home Team Number Of Goals In 2nd Half',
    149: 'Home Team Number Of Goals',
    150: 'Away Team Number Of Goals',
    151: 'Double Chance 2nd Half',
    153: 'Under/Over 1st Period - Home Team',
    154: 'Under/Over 2nd Period - Home Team',
    155: 'Under/Over 1st Period - Away Team',
    156: 'Under/Over 2nd Period - Away Team',
    157: 'Under/Over Yellow Cards - 1st Half',
    158: 'Under/Over Yellow Cards',
    159: 'Will Be a Tie-Break In The Match?',
    160: 'Race Winner',
    161: '10 Minute Result',
    162: '10 Minute Under/Over',
    163: 'Number of Goals 2nd Half',
    164: 'Will Be a Tie-Break In The First Set?',
    165: 'Under/Over Sets',
    166: 'Under/Over Games',
    168: 'Will Be An Own Goal?',
    169: 'To Score In Both Halves',
    170: 'Odd/Even Corners',
    171: 'To Win Either Half',
    175: 'Will Be a Tie-Break In The Second Set?',
    176: 'Will Be a Tie-Break In The Third Set?',
    177: 'Will Be a Tie-Break In The Fourth Set?',
    178: 'Will Be a Tie-Break In The Fifth Set?',
    180: 'Under/Over Throw-Ins',
    181: 'Under/Over Yellow Cards - Home Team',
    182: 'Under/Over Yellow Cards - Home Team 1st Half',
    183: 'Under/Over Yellow Cards - Home Team 2nd Half',
    184: 'Under/Over Yellow Cards - Away Team',
    185: 'Under/Over Yellow Cards - Away Team 1st Half',
    // 186: 'Under/Over Yellow Cards - Away Team 2nd Half',
    187: 'Under/Over Yellow Cards - 2nd Half',
    188: 'First Yellow Card',
    190: 'First Yellow Card 2nd Half',
    191: 'Will Any Player Score a Hat-Trick?',
    192: 'Will A Goal Be Scored In Both Halves?',
    194: 'Home Team To Keep A Clean Sheet 1st Period',
    195: 'Home Team To Keep A Clean Sheet 2nd Period',
    196: 'Away Team To Keep A Clean Sheet 1st Period',
    197: 'Away Team To Keep A Clean Sheet 2nd Period',
    198: 'Odd/Even - Home Team',
    199: 'Odd/Even - Away Team',
    200: 'Will The First Scoring Team Win?',
    201: 'Asian Handicap Games',
    202: '1st Period Winner Home/Away',
    203: '2nd Period Winner Home/Away',
    204: '3rd Period Winner Home/Away',
    205: '4th Period Winner Home/Away',
    206: '5th Period Winner Home/Away',
    207: '15 Minute Result',
    208: '30 Minute Result',
    209: 'Total Corners - 1st Half',
    210: 'Total Corners - 2nd Half',
    211: 'Both Teams To Score 2nd Half',
    212: 'Both Teams To Score In Both Halves',
    213: 'Draw In Both Halves',
    214: 'Under/Over Cards',
    215: 'Away Team to Score 1st Half',
    216: 'Away Team to Score 2nd Half',
    217: 'Correct Score 3rd Period',
    218: 'Home Team to Score 1st Half',
    // 219: 'Home Team to Score 2nd Half',
    220: 'Under/Over - Away Team Including Overtime',
    221: 'Under/Over - Home Team Including Overtime',
    222: 'Under/Over 3rd Period - Away Team',
    223: 'Under/Over 3rd Period - Home Team',
    224: 'Double Chance 3rd Period',
    // 225: 'Double Chance 4th Period',
    219: '12 Including Overtime', //Betradar
    227: 'Race Winner 2 Places',
    228: 'Race Winner 3 Places',
    229: 'Race Winner 4 Places',
    233: 'To Win Not to Nil',
    235: '1st 5 Innings Winner',
    236: '1st 5 Innings Under/Over',
    237: 'Will There Be A Score In The 1st Inning',
    7: 'Remaining Match',
    239: 'Goals Range',
    242: '1st Period Odd/Even - Home Team',
    243: '1st Period Odd/Even - Away Team',
    244: 'Highest Opening Partnership',
    245: 'Goal/No Goal',
    247: 'Remaining 1st Half',
    250: 'Corners Handicap - 1st Half',
    254: 'Cards Range',
    255: 'Time Of 1st Card',
    256: 'Home Team - Cards Range',
    257: 'Away Team - Cards Range',
    258: 'Corners Range',
    259: 'Team With Most Corners',
    260: 'Team With Most 1st Half Corners',
    261: 'Team With Most 2nd Half Corners',
    262: 'Home Team - Corners Range',
    263: 'Away Team - Corners Range',
    264: 'Home Team - 1st Half Corners Range',
    265: 'Away Team - 1st Half Corners Range',
    266: 'Home Team - 2nd Half Corners Range',
    267: 'Away Team - 2nd Half Corners Range',
    269: 'Goals Range 1st Period',
    271: 'Chance Mix',
    274: 'Outright Winner',
    275: 'Goals Range 2nd Period',
    278: 'Goals Range Home Team',
    279: 'Goals Range Away Team',
    281: '1st 5 Innings Asian Handicap',
    282: '1X2 Halftime',
    283: 'Asian Handicap 2nd Half',
    284: '1X2 2nd Half',
    285: 'Odd/Even 2nd Half',
    286: '12 2nd Half',
    287: 'Under/Over 4th Period - Home Team',
    288: 'Under/Over 4th Period - Away Team',
    289: '2nd Period Odd/Even - Home Team',
    290: '3rd Period Odd/Even - Home Team',
    291: '4th Period Odd/Even - Home Team',
    292: '2nd Period Odd/Even - Away Team',
    293: '3rd Period Odd/Even - Away Team',
    294: '4th Period Odd/Even - Away Team',
    297: 'SP',
    299: 'Point Winner',
    300: 'Game Winner',
    301: 'Goal Before',
    302: 'Goal After',
    304: 'Team With Highest Scoring Period',
    305: 'Corners - Under/Exactly/Over',
    306: 'First Goal Last Goal',
    307: 'European Handicap Games',
    308: 'Under/Over Highest Scoring Period',
    309: 'Under/Over Lowest Scoring Period',
    310: 'Series Winner',
    317: 'Under/Exactly/Over - 1st Period',
    318: 'Under/Exactly/Over - 2nd Period',
    319: 'Under/Exactly/Over - 3rd Period',
    320: 'Under/Exactly/Over - 4th Period',
    322: 'Under/Exactly/Over',
    329: '1st Period Race To',
    330: '2nd Period Race To',
    331: '3rd Period Race To',
    332: '4th Period Race To',
    333: '5th Period Race To',
    337: 'Under/Over - 2nd Half',
    338: 'Next Goal 1st Period',
    339: 'Next Goal 2nd Period',
    341: 'Race To',
    342: 'Asian Handicap Including Overtime',
    343: 'Game Range 1st Period',
    344: 'Game Range 2nd Period',
    345: 'Game Range 3rd Period',
    346: 'Game Range 4th Period',
    347: 'Game Range 5th Period',
    348: '6th Period Winner',
    349: '7th Period Winner',
    350: '6th Period Odd/Even',
    351: '7th Period Odd/Even',
    352: 'Under/Over 6th Period',
    353: 'Under/Over 7th Period',
    354: 'Under/Over Halftime - Home Team',
    355: 'Under/Over Halftime - Away Team',
    356: 'Method Of Dismissal 6-Way',
    357: 'Dismissal Method',
    366: 'Total Runs Odd/Even in Over - Home Team',
    367: 'Total Runs Odd/Even in Over - Away Team',
    370: 'Total Runs in Over - Home Team',
    371: 'Total Runs in Over - Away Team',
    386: 'European Handicap Including Overtime',
    387: 'Odd/Even - Home Team Including Overtime',
    388: 'Odd/Even - Away Team Including Overtime',
    390: 'HT/FT Including Overtime',
    391: 'Next Goal - Extra Time',
    392: 'Home Team Penalty Shootout',
    393: 'Away Team Penalty Shootout',
    394: 'Correct Score 4th Period',
    395: 'Correct Score 5th Period',
    398: 'Odd/Even Cards',
    400: 'Odd/Even Cards 1st Half',
    401: 'Under/Over Corners 1st Half - Home Team',
    402: 'Under/Over Corners 1st Half - Away Team',
    403: 'Under/Over Corners 2nd Half',
    404: 'Under/Over Corners 2nd Half - Home Team',
    405: 'Under/Over Corners 2nd Half - Away Team',
    406: 'Odd/Even Corners 1st Half',
    407: 'Asian Handicap Cards',
    408: 'Asian Handicap Cards 1st Half',
    409: '1X2 Corners',
    410: '1X2 Corners 1st Half',
    411: '12 Corners',
    414: 'Under/Over Cards 1st Half',
    415: '1X2 And Both Teams To Score',
    416: 'Under/Over - Extra Time',
    417: 'Under/Over - Extra Time 1st Half',
    419: '1X2 - Extra Time',
    427: '1X2 And Under/Over',
    428: 'Multiple Correct Score',
    430: 'Under/Exactly/Over - 1st Half',
    431: 'Under/Exactly/Over - 2nd Half',
    433: 'European Handicap Corners',
    434: '8th Period Winner',
    435: '9th Period Winner',
    436: 'Under/Over 8th Period',
    437: 'Under/Over 9th Period',
    438: 'Total Runs Odd/Even in Over',
    439: 'Total Runs in Over',
    445: '6th Period Winner Home/Away',
    446: '7th Period Winner Home/Away',
    447: 'Asian Handicap 6th Period',
    448: 'Asian Handicap 7th Period',
    449: 'Asian Handicap 8th Period',
    450: 'Asian Handicap 9th Period',
    451: '8th Period Winner Home/Away',
    452: '9th Period Winner Home/Away',
    453: 'First Score Method',
    454: 'First Score Method - Home Team',
    455: 'First Score Method - Away Team',
    456: 'Double Chance 1st Period',
    457: 'Double Chance 2nd Period',
    459: 'Home Team to Score in Every Quarter',
    460: 'Away Team to Score in Every Quarter',
    462: 'Top 5 Winner',
    463: 'Top 10 Winner',
    464: '12 2nd Half Including Overtime',
    465: '4th Period Winner Home/Away Including Overtime',
    466: 'Under/Over 4th Period Including Overtime',
    467: 'Asian Handicap 4th Period Including Overtime',
    468: 'Asian Handicap 2nd Half Including Overtime',
    469: 'Under/Over 2nd Half Including Overtime',
    472: 'Will Game Go to Shootout?',
    475: 'Under/Over Runs In Over - Home Team 1st Inning',
    476: 'Under/Over Runs In Over - Away Team 1st Inning',
    477: 'Under/Over Runs In Over - Home Team 2nd Inning',
    478: 'Under/Over Runs In Over - Away Team 2nd Inning',
    479: 'Odd/Even Runs In Over - Home Team 1st Inning',
    480: 'Odd/Even Runs In Over - Away Team 1st Inning',
    481: 'Odd/Even Runs In Over - Home Team 2nd Inning',
    482: 'Odd/Even Runs In Over - Away Team 2nd Inning',
    483: 'Will Home Team Get A Wicket in Over - 1st Inning?',
    484: 'Will Away Team Get A Wicket in Over - 1st Inning?',
    485: 'Will Home Team Get A Wicket in Over - 2nd Inning?',
    486: 'Will Away Team Get A Wicket in Over - 2nd Inning?',
    523: 'Under/Over And Both Teams To Score',
    524: '1st 7 Innings Winner',
    525: '1st 7 Innings Under/Over',
    526: '1st 7 Innings Asian Handicap',
    529: 'Odd/Even 2nd Half Including Overtime',
    553: 'Under/Over Shootout Penalties Taken',
    554: '12 Shootout',
    555: 'Asian Handicap Shootout',
    556: 'Correct Score Shootout',
    557: 'Under/Over Shootout',
    558: 'Under/Over Shootout - Home Team',
    559: 'Under/Over Shootout - Away Team',
    560: 'Team To Take Last Shootout Penalty',
    563: 'Race To Including Overtime',
    564: '1X2 And Under/Over 5 way',
    566: '10th Period Winner Home/Away',
    567: '11th Period Winner Home/Away',
    568: '12th Period Winner Home/Away',
    569: '13th Period Winner Home/Away',
    570: '14th Period Winner Home/Away',
    571: '15th Period Winner Home/Away',
    573: '16th Period Winner Home/Away',
    574: '17th Period Winner Home/Away',
    575: '18th Period Winner Home/Away',
    576: '19th Period Winner Home/Away',
    577: '10 Minute Under/Over - Corners',
    578: '10 Minute Under/Over - Cards',
    579: 'Next Corner',
    586: 'To Finish In Top Half',
    587: 'To Finish In Bottom Half',
    588: 'To Finish Bottom',
    589: 'Not To Finish In Top 4',
    590: 'To Be Relegated',
    591: 'Not To Be Relegated',
    592: 'To Reach Final',
    593: 'To Finish In Bottom 6',
    594: 'To Be Promoted',
    595: 'First Goal Time',
    596: 'Goals Range Home Team 1st Period',
    597: 'Goals Range Home Team 2nd Period',
    598: 'Goals Range Away Team 1st Period',
    599: 'Goals Range Away Team 2nd Period',
    600: '1st Period Winner And Both Teams To Score',
    601: 'HT/FT Double Chance',
    602: 'Correct Score Including Overtime',
    603: 'Negative HT/FT',
    604: 'Goals Range Home Team Both Periods',
    605: 'Goals Range Away Team Both Periods',
    606: 'Winner And Goals Type One',
    607: 'Double Chance And Goals',
    608: 'Winner And Goals Type Two',
    609: 'Both Teams To Score And Goals',
    610: 'Double Chance Mix',
    628: 'To Finish In Top 7',
    629: 'To Finish In Top 6',
    630: 'To Finish In Top 3',
    631: 'To Finish In Top 2',
    632: 'To Finish In Top 4',
    633: 'To Finish In Top 5',
    634: 'Odd/Even Rounds 1st Period',
    635: 'Odd/Even Rounds 2nd Period',
    636: 'Odd/Even Rounds 3rd Period',
    637: 'Will There Be An Ace?',
    638: 'Will There Be A Knife Kill?',
    639: '1st Round Winner',
    640: '16th Round Winner',
    641: 'Will there be a knife kill? - 1st Period',
    642: 'Will there be a knife kill? - 2nd Period',
    643: 'Will there be a knife kill? - 3rd Period',
    644: 'Will There Be An Ace? - 1st Period',
    645: 'Will There Be An Ace? - 2nd Period',
    646: 'Will There Be An Ace? - 3rd Period',
    647: 'Will There Be Overtime? - 1st Period',
    648: 'Will There Be Overtime? - 2nd Period',
    649: 'Will There Be Overtime? - 3rd Period',
    650: '16th Round Winner - 1st Period',
    651: '16th Round Winner - 2nd Period',
    652: '16th Round Winner - 3rd Period',
    653: '1st Round Winner - 1st Period',
    654: '1st Round Winner - 2nd Period',
    655: '1st Round Winner - 3rd Period',
    660: 'Game Duration',
    662: 'Odd/Even Rounds',
    663: '1st Period Duration',
    664: '2nd Period Duration',
    665: '3rd Period Duration',
    666: '1st Period - 1st Roshan',
    667: '2nd Period - 1st Roshan',
    668: '3rd Period - 1st Roshan',
    669: '1st Period - 1st Blood',
    670: '2nd Period - 1st Blood',
    671: '3rd Period - 1st Blood',
    672: '1st Period - 1st Tower',
    673: '2nd Period - 1st Tower',
    674: '3rd Period - 1st Tower',
    675: '1st Period - 1st Barracks',
    677: '2nd Period - 1st Barracks',
    678: '3rd Period - 1st Barracks',
    679: '1st Period - 1st Turret',
    680: '2nd Period - 1st Turret',
    681: '3rd Period - 1st Turret',
    682: 'Race To Kills - 1st Period',
    683: 'Race To Kills - 2nd Period',
    684: 'Race To Kills - 3rd Period',
    685: 'Both Teams to kill a Dragon - 1st Period',
    686: 'Both Teams to kill a Dragon - 2nd Period',
    687: 'Both Teams to kill a Dragon - 3rd Period',
    688: 'Both Teams to kill a Inhibitor - 1st Period',
    689: 'Both Teams to kill a Inhibitor - 2nd Period',
    690: 'Both Teams to kill a Inhibitor - 3rd Period',
    691: 'Odd/Even Kills',
    692: 'Odd/Even Kills - 1st Period',
    693: 'Odd/Even Kills - 2nd Period',
    694: 'Odd/Even Kills - 3rd Period',
    695: 'HT/FT And Goals',
    697: 'To Finish In Top 8',
    711: 'Player To Score In Anytime',
    712: 'First Player To Score',
    713: 'Last Player To Score',
    714: 'Player To Score 2 Or More Goals',
    715: 'Player To Score 3 Or More Goals',
    727: '1st Period Both Teams To Score',
    728: '2nd Period Both Teams To Score',
    729: '3rd Period Both Teams To Score',
    730: '4th Period Both Teams To Score',
    731: '1st Period Home Team To Score',
    732: '2nd Period Home Team To Score',
    733: '3rd Period Home Team To Score',
    734: '4th Period Home Team To Score',
    735: '1st Period Away Team To Score',
    736: '2nd Period Away Team To Score',
    737: '3rd Period Away Team To Score',
    738: '4th Period Away Team To Score',
    751: '1st Half Race To',
    752: '2nd Half Race To - Including Overtime',
    754: 'Game To Deuce',
    755: 'Extra Time Corners - Under/Exactly/Over',
    756: 'Under/Over- Extra Time Corners',
    757: 'Asian Handicap - Extra Time',
    758: '10 Minute Under/Over - Free Kicks',
    759: '10 Minute Under/Over - Throw-ins',
    760: '10 Minute Under/Over - Goal Kicks',
    762: 'To Win The Toss',
    763: 'Under/Over Runs In Match',
    765: 'To Score Most Fours',
    766: 'Under/Over - Match Fours',
    767: 'Under/Over - Match Sixes',
    768: 'A Fifty To Be Scored In The Match',
    769: 'A Hundred To Be Scored In The Match',
    770: 'Most Run Outs',
    791: 'Top Batsman - Home Team',
    792: 'Top Batsman - Away Team',
    793: 'Top Bowler - Home Team',
    794: 'Top Bowler - Away Team',
    795: 'Man Of The Match',
    806: 'Batsman To Score a Fifty in The match',
    808: 'Race To 10 Runs',
    809: 'Highest 1st 6 Overs Score',
    813: 'Player To Score Most Sixes',
    814: 'Highest Individual Score',
    819: 'To Have A Break Point In The Game',
    820: 'Race To Corners',
    822: 'Top New Team ',
    824: 'Player To Be Booked',
    825: 'Player To Be Sent Off',
    834: 'Extra Points Set',
    835: 'Asian Under/Over',
    836: 'Asian Under/Over 1st Period',
    837: '1st Half Home Team Win To Nil',
    838: '1st Half Away Team Win To Nil',
    839: '1st Half 1X2 And Under/Over',
    840: '1st Period Home Team Win To Nil',
    841: '1st Period Away Team Win To Nil',
    842: 'Under/Exactly/Over - Home Team',
    843: 'Under/Exactly/Over - Away Team',
    844: 'First Team To Score - Home/Away',
    845: 'Team To Call First Time Out',
    846: 'Under/Over Hits - Including Overtime',
    849: 'Under/Over 5th Inning',
    851: 'Team With Highest Scoring Half',
    852: ' Either Team to Score 3 Unanswered Times',
    853: '12 And Under/Over',
    854: 'Special Team or Defensive Touchdown Scored',
    855: 'Points Range',
    859: '147 Break In Match',
    861: 'First Score Method 3 Way - Home Team',
    862: 'First Score Method 3 Way - Away Team',
    863: 'Team with Highest Scoring Period -1X2',
    864: 'Team First Goalscorer',
    865: 'Team Last Goalscorer',
    866: 'Asian Handicap Sets',
    870: 'First Score Method 6 Way Home/Away',
    878: 'Goals Range 3rd Period',
    879: 'Under/Over Tries',
    880: 'First Half Corners - Under/Exactly/Over',
    881: 'First Goal Method',
    882: 'Number of points',
    891: 'Team To Score Longest Touchdown',
    892: 'Team To Score Longest Field Goal',
    893: 'Longest Touchdown Scored',
    894: 'Shortest Touchdown Scored',
    895: 'Longest Field Goal Scored',
    896: 'Time Of First Team Goal',
    897: 'Game Score After 2 Points',
    898: 'Game Score After 3 Points',
    899: 'Game Score After 4 Points',
    900: 'Game To Score',
    901: '1st Period Lead After',
    902: '2nd Period Lead After',
    903: '3rd Period Lead After',
    904: '4th Period Lead After',
    905: '5th Period Lead After',
    906: 'Score After 4 Games',
    907: 'Score After 6 Games',
    908: 'To Break Serve In Set',
    909: 'Home To Win In Straight Sets',
    910: 'Away To Win In Straight Sets',
    911: 'Score After 2 Sets',
    912: 'Score After 3 Sets',
    914: 'Under/Over Field Goals Scored - 1st Half',
    915: 'Under/Over Touchdowns Scored - 1st Half',
    916: 'Under/Over Home Team Touchdowns - 1st Half',
    917: 'Under/Over Away Team Touchdowns - 1st Half',
    920: 'Under/Over Touchdowns',
    921: 'Under/Exactly/Over - Touchdowns',
    922: 'Under/Over Field Goals',
    923: 'Under/Exactly/Over - Field Goals',
    924: 'Time Of First Field Goal Scored',
    925: 'Time Of First Touchdown Scored',
    926: 'Time Of First Score',
    927: 'Home Team Under/Over Touchdowns',
    928: 'Away Team Under/Over Touchdowns',
    929: 'Home Team Under/Over Field Goals',
    930: 'Away Team Under/Over Field Goals',
    932: 'Team to Have First Coaches Challenge',
    933: 'Team to Commit First Accepted Penalty',
    934: 'Safety Scored',
    935: 'European Cards Handicap',
    936: 'Asian Under/Over Cards',
    938: 'Under/Over Cards - Home Team',
    939: 'Under/Over Cards - Away Team',
    940: 'Time Of First Card',
    941: 'Next Player To Score',
    942: 'Top Nationality - Asian ',
    943: 'Top Nationality - Australian ',
    944: 'Top Nationality - European',
    945: 'Top Nationality - Great Britain & Ireland',
    946: 'Top Nationality - Rest Of The World',
    947: 'Under/Over Aces',
    948: 'Under/Over Aces - Home Team',
    949: 'Exact Number Of Aces',
    950: 'Under/Over Aces - Away Team',
    951: 'Correct Number Of Aces',
    955: '1X2 Aces',
    956: 'Exact Number Of Aces - Home Team',
    957: 'Exact Number Of Aces - Away Team',
    958: 'Aces Asian Handicap',
    959: 'Race To Aces ',
    960: 'Under/Over Aces - 1st Period',
    976: '1X2 Aces - 1st Period',
    982: 'Aces Asian Handicap - 1st Period',
    986: '12 Aces',
    989: 'Under/Over Kills - 1st Period',
    990: 'Under/Over Kills - 2nd Period',
    991: 'Under/Over Kills - 3rd Period',
    992: 'Next Touchdown Scorer - Home Team',
    993: 'Next Touchdown Scorer - Away Team',
    994: 'Next Touchdown Scorer',
    995: 'Player To Score Touchdown In Anytime',
    996: 'Player To Score 2 Or More Touchdowns',
    997: 'Player To Score 3 Or More Touchdowns',
    998: ' First Match Corner - 12',
    999: 'Last Match Corner - 12',
    1000: 'Number Of Sets',
    1001: 'Under/Over Double Faults',
    1002: 'Under/Over Double Faults - Away Team',
    1003: 'Under/Over Double Faults - Home Team',
    1004: 'Under/Over Double Faults  - 1st Period',
    1010: '12 Double Faults',
    1011: '12 Double Faults - 1st Period',
    1013: '1X2 Double Faults',
    1016: 'Exact Number Of Double Faults',
    1019: 'Exact Number Of Double Faults - Home Team',
    1020: 'Exact Number Of Double Faults - Away Team',
    1027: 'Correct Number Of Double Faults',
    1030: 'Time Of First Corner',
    1031: 'Multiple Corners ',
    1034: '20th Period Winner Home/Away',
    1035: '21th Period Winner Home/Away',
    1036: '22th Period Winner Home/Away',
    1037: '23th Period Winner Home/Away',
    1038: '24th Period Winner Home/Away',
    1039: '25th Period Winner Home/Away',
    1040: '26th Period Winner Home/Away',
    1041: '27th Period Winner Home/Away',
    1042: '28th Period Winner Home/Away',
    1043: '29th Period Winner Home/Away',
    1044: '30th Period Winner Home/Away',
    1046: '32th Period Winner Home/Away',
    1047: '33th Period Winner Home/Away',
    1050: 'Race To 20 Points',
    1051: 'Under/Over 2nd Half - Home Team',
    1052: 'Under/Over 2nd Half - Away Team',
    1053: 'Asian Under/Over 2nd Period',
    1054: 'Asian Under/Over 3rd Period',
    1055: 'Asian Under/Over 4th Period',
    1061: 'Asian Under/Over Including Overtime ',
    1062: 'Highest Scoring Half - Home Team',
    1063: 'Highest Scoring Half - Away Team',
    1065: 'Home Team - First Goalscorer',
    1066: 'Home Team - Last Goalscorer',
    1067: 'Away Team - First Goalscorer',
    1068: 'Away Team - Last Goalscorer',
    1069: 'Under/Over Player Points',
    1070: 'Under/Over Player Blocks',
    1071: 'Under/Over Player Assists',
    1072: 'Under/Over Player Rebounds',
    1073: 'Under/Over Player Steals',
    1074: 'Under/Over Player Turnovers',
    1075: 'Under/Over Player 3 Points Made',
    1076: 'Under/Over Player 3 Points Attempted',
    1077: 'Under/Over Player 2 Points Made',
    1078: 'Under/Over Player 2 Points Attempted',
    1079: 'Under/Over Player Free Throws Made',
    1080: 'Under/Over Player Free Throws Attempted',
    1081: '1X2 Yellow Cards',
    1082: 'Double Chance Yellow Cards',
    1083: 'Asian Handicap Yellow Cards',
    1084: 'Correct Score Yellow Cards',
    1085: 'Under/Over Red Cards',
    1086: 'Under/Exactly/Over Booking Points',
    1087: 'Under/Exactly/Over Booking Points - Home Team',
    1088: 'Under/Exactly/Over Booking Points - Away Team',
    1089: 'Under/Over Player Steals And Blocks',
    1090: 'Under/Over Player Points, Assists And Rebounds',
    1091: 'Under/Over Player Assists And Rebounds',
    1092: 'Under/Over Player Points And Rebounds',
    1093: 'Under/Over Player Points And Assists',
    1094: 'Player Double Double',
    1095: 'Player Triple Double',
    1096: 'Under/Exactly/Over Booking Points - 1st Period',
    1100: 'Set Winner ',
    1101: 'Under/Over 180\'s In a Set',
    1102: 'Set/Leg Winner ',
    1103: 'Set/Leg Total Checkouts Under/Over ',
    1105: 'Under/Over Total 180\'s - Home Team',
    1106: 'Under/Over Total 180\'s - Away Team',
    1112: 'Under/Over Player Goals',
    1115: 'Under/Over Player Goals Allowed',
    1120: 'Under/Over Rounds - 1st Period',
    1121: 'Under/Over Rounds - 2nd Period',
    1122: 'Under/Over Rounds - 3rd Period',
    1123: '4th Period - 1st Blood',
    1124: '5th Period - 1st Blood',
    1125: 'Both Teams To Kill a Dragon',
    1126: 'Both Teams To Kill a Baron',
    1127: 'Both Teams To Kill a Inhibitor',
    1128: 'Under/Over Barons Slain',
    1129: 'Under/Over Barons Slain - 1st Period',
    1130: 'Under/Over Barons Slain - 2nd Period',
    1131: 'Under/Over Barons Slain - 3rd Period',
    1132: 'Under/Over Dragons Slain',
    1133: 'Under/Over Dragons Slain - 1st Period',
    1134: 'Under/Over Dragons Slain - 2nd Period',
    1135: 'Under/Over Dragons Slain - 3rd Period',
    1136: 'Under/Over 180\'s',
    1137: 'First 180',
    1138: 'Last 180',
    1139: 'Under/Over Highest Checkout Score',
    1140: 'Most 180\'s',
    1141: 'Under/Over Highest Checkout Score - Home Team',
    1142: 'Under/Over Highest Checkout Score - Away Team',
    1143: 'First Player To Score Touchdown',
    1144: 'Last Player To Score Touchdown',
    1145: 'First Player To Score Touchdown - Home Team',
    1146: 'First Player To Score Touchdown - Away Team',
    1147: 'Under/Over Kills - 4th Period',
    1148: 'Under/Over Kills - 5th Period',
    1149: 'Asian Handicap Kills - 1st Period',
    1150: 'Asian Handicap Kills - 2nd Period',
    1151: 'Asian Handicap Kills - 3rd Period',
    1152: 'Asian Handicap Kills - 4th Period',
    1153: 'Asian Handicap Kills - 5th Period',
    1154: 'Under/Exactly/Over 180\'s',
    1155: 'Under/Exactly/Over Total 180\'s - Home Team',
    1156: 'Under/Exactly/Over Total 180\'s - Away Team',
    1157: 'Most Checkouts',
    1158: 'First Try Scorer',
    1159: 'Last Try Scorer',
    1160: 'Anytime Try Scorer',
    1162: 'Under/Over Towers Destroyed - 1st Period',
    1163: 'Under/Over Towers Destroyed - 2nd Period',
    1164: 'Under/Over Towers Destroyed - 3rd Period',
    1165: '1st Period - 1st Baron',
    1166: '2nd Period - 1st Baron',
    1167: '3rd Period - 1st Baron',
    1168: '4th Period - 1st Baron',
    1169: '5th Period - 1st Baron',
    1170: '1st Period - 1st Dragon',
    1171: '2nd Period - 1st Dragon',
    1172: '3rd Period - 1st Dragon',
    1173: '4th Period - 1st Dragon',
    1174: '5th Period - 1st Dragon',
    1175: 'Most Kills - 1st Period',
    1176: 'Most Kills - 2nd Period',
    1177: 'Most Kills - 3rd Period',
    1178: 'Most Kills - 4th Period',
    1179: 'Most Kills - 5th Period',
    1180: 'Most Kills - 6th Period',
    1181: 'Most Kills - 7th Period',
    1182: 'Player To Score 2 Or More Trys',
    1183: 'Player To Score 3 Or More Trys',
    1184: 'Not To Win The Leauge',
    1185: 'Under/Exactly/Over Including Overtime - Home Team',
    1186: 'Under/Exactly/Over Including Overtime - Away Team',
    1187: 'Under/Exactly/Over Including Overtime ',
    1188: 'Under/Over Substitutions',
    1189: 'Under/Over Substitutions - 1st Period',
    1190: 'Last Player To Score Touchdown - Home Team',
    1191: 'Last Player To Score Touchdown - Away Team',
    1192: 'First Player To Hit an Ace',
    1193: 'Under/Over Player Quarterback Passing Yards',
    1194: 'Under/Over Player Passing Touchdowns',
    1195: 'Under/Over Longest Player Pass Completion',
    1196: 'Under/Over Player Receiving Yards',
    1197: 'Under/Over Player Interceptions',
    1198: 'Under/Over Player Kicking Points',
    1199: 'Under/Over Player Rushing & Receiving Yards',
    1200: 'Under/Over Player Rushing Yards',
    1201: 'Under/Over Player Longest Reception',
    1202: 'Under/Over Player Receptions',
    1203: 'Under/Over Player Field Goals Made',
    1204: 'Under/Over Player Field Goals Attempted',
    1205: 'Under/Over Player Quarterback Passing Touchdowns',
    1206: 'Under/Over Player Receiver Reception Completions',
    1207: 'Under/Over Player Quarterback Passes Completed',
    1208: 'Under/Over Player Rushing Touchdowns',
    1209: 'Under/Over Player Receiver Reception Attempts',
    1210: 'Under/Over Player Quarterback Passes Attempted',
    1211: '1X2 Fouls',
    1212: 'Double Chance Fouls',
    1213: 'Under/Over Fouls - 1st Period',
    1214: 'Under/Over Fouls - 2nd Period',
    1215: 'Asian Handicap Fouls',
    1216: 'Under/Over Fouls - Home Team',
    1217: 'Under/Over Fouls - Away Team',
    1218: 'Under/Over Player Rush Attempts',
    1219: 'Under/Over Player Receiving Touchdowns',
    1222: 'Under/Over Throw-Ins - Home Team',
    1223: 'Under/Over Throw-Ins - Away Team',
    1224: '1X2 Throw-Ins',
    1225: 'Double Chance Throw-Ins',
    1226: 'Under/Over Throw-Ins - 1st Period',
    1227: 'Throw-ins Handicap',
    1228: 'Throw-ins Handicap - 1st Period',
    1229: 'Under/Over Shots on Target - Home Team',
    1230: 'Under/Over Shots on Target - Away Team',
    1231: 'Asian Handicap Shots on Target',
    1232: 'Asian Handicap Shots On Target - 1st Period',
    1233: 'Under/Over Shots On Target - 1st Period',
    1234: '1X2 Shots on Target',
    1235: 'Double Chance Shots on Target',
    1236: 'Under/Over Total Shots - Home Team',
    1237: 'Under/Over Total Shots - Away Team',
    1238: 'Double Chance Total Shots',
    1239: '1X2 Total Shots',
    1240: 'Asian Handicap Total Shots',
    1241: 'Asian Handicap Total Shots - 1st Period',
    1242: 'Under/Over Total Shots - 1st Period',
    1243: 'Double Chance Corners',
    1244: 'Double Chance Corners - 1st Period',
    1245: 'Corners From All 4 Corners Of The Pitch',
    1246: 'Double Chance Yellow Cards - 1st Period',
    1247: '1X2 Yellow Cards - 1st Period',
    1248: 'Asian Handicap Yellow Cards - 1st Period',
    1249: 'Double Chance & Under/Over',
    1250: 'Double Chance & Both Teams To Score',
    1251: '1st Period - 1st Inhibitor',
    1252: '2nd Period - 1st Inhibitor',
    1253: '3rd Period - 1st Inhibitor',
    1254: '4th Period - 1st Inhibitor',
    1255: '5th Period - 1st Inhibitor',
    1256: 'Both Teams To Kill a Baron - 1st Period',
    1257: 'Both Teams To Kill a Baron - 2nd Period',
    1258: 'Both Teams To Kill a Baron - 3rd Period',
    1259: 'Both Teams To Kill a Baron - 4th Period',
    1260: 'Both Teams To Kill a Baron - 5th Period',
    1261: 'Both Teams to kill a Dragon - 4th Period',
    1262: 'Both Teams to kill a Dragon - 5th Period',
    1263: 'Both Teams to kill a Inhibitor - 4th Period',
    1264: 'Both Teams to kill a Inhibitor - 5th Period',
    1265: 'Will There Be An Ace Next Game?',
    1266: 'Will There Be Double Fault Next Game?',
    1267: 'Odd/Even Fouls',
    1268: 'Asian Handicap Ball Possession ',
    1269: 'Double Chance Offsides',
    1270: 'Asian Handicap Offsides',
    1271: 'Under/Over Offsides - Home Team',
    1272: 'Under/Over Offsides - Away Team',
    1273: '1X2 Offsides',
    1274: 'Under/Over Kills 1st Period - Home Team',
    1275: 'Under/Over Kills 2nd Period - Home Team',
    1276: 'Under/Over Kills 3rd Period - Home Team',
    1277: 'Under/Over Kills 1st Period - Away Team',
    1278: 'Under/Over Kills 2nd Period - Away Team',
    1279: 'Under/Over Kills 3rd Period - Away Team',
    1280: 'Under/Over Wides',
    1281: 'Under/Over Ducks',
    1282: 'Under/Over Wickets',
    1283: 'Under/Over Match Fours - Home Team',
    1284: 'Under/Over Match Sixes - Home Team',
    1285: 'Under/Over Match Fours - Away Team',
    1286: 'Under/Over Match Sixes - Away Team',
    1287: 'Under/Over Extras',
    1288: 'Fall Of 1st Wicket - Home Team',
    1289: 'Fall Of 1st Wicket - Away Team',
    1290: 'Team Of Top Batsman',
    1291: 'Under/Over Match Sixes Home Team - 1st Inning',
    1292: 'Under/Over Match Sixes Away Team - 1st Inning',
    1293: 'Under/Over Match Fours Home Team - 1st Inning',
    1294: 'Under/Over Match Fours Away Team - 1st Inning',
    1295: 'Under/Over Top Batsman',
    1296: '1st inning 1st Over Dismissal - Home Team',
    1297: '1st Inning 2nd Over Dismissal - Home Team',
    1298: '1st Inning 3rd Over Dismissal - Home Team',
    1299: '1st Inning 2nd Over Dismissal - Away Team',
    1300: '1st Inning 3rd Over Dismissal - Away Team',
    1301: '1st Inning 1st Over Dismissal - Away Team',
    1302: 'Highest Scoring Over - Under/Over',
    1303: '1st Inning 2nd Over Under/Over - Home Team',
    1304: '1st Inning 3rd Over Under/Over - Home Team',
    1306: '1st Inning 3rd Over Under/Over - Away Team',
    1307: '1st Inning 2nd Over Under/Over - Away Team',
    1309: 'Winning Margin - Teams',
    1310: 'Winning Margin - Teams Including Overtime',
    1312: 'Winning Margin Exact',
    1314: 'Winning Margin Ranges',
    1315: 'Winning Margin Exact - Teams',
    1316: 'Winning Margin - Teams With Any Other Score',
    1318: 'Asian Handicap Fouls - 1st Period',
    1319: 'Asian Handicap Offsides - 1st Period',
    1320: 'Asian Handicap Offsides - 2nd Period',
    1321: 'Asian Handicap Fouls - 2nd Period',
    1322: 'Asian Handicap Shots On Target - 2nd Period',
    1324: 'Under/Over Offsides - 1st Period',
    1325: 'Under/Over Offsides - 2nd Period',
    1326: 'First Foul',
    1327: 'First Two Pointer',
    1328: 'First Three Pointer',
    1329: 'Last Two Pointer',
    1330: 'Last Three Pointer',
    1331: 'Last Free Throw Scored',
    1332: 'First Free Throw Scored',
    1333: 'First Rebound',
    1334: 'Last Timeout',
    1335: 'Winning Margin Teams - 1st Period',
    1336: 'Winning Margin Teams - 2nd Period',
    1337: 'Winning Margin Teams - 3rd Period',
    1338: 'Winning Margin Teams - 4th Period',
    1339: 'Winning Margin Teams - 5th Period',
    1340: 'Last Foul',
    1341: 'Under/Over 10th Period',
    1342: 'Under/Over 11th Period',
    1343: 'Under/Over 12th Period',
    1344: 'Under/Over 13th Period',
    1345: 'Under/Over 14th Period',
    1346: 'Under/Over 15th Period',
    1347: 'Under/Over 16th Period',
    1348: 'Under/Over 17th Period',
    1349: 'Under/Over 18th Period',
    1350: 'Under/Over 19th Period',
    1351: 'Under/Over 20th Period',
    1352: 'Under/Over 21st Period',
    1353: 'Under/Over 22nd Period',
    1354: 'Under/Over 23rd Period',
    1355: 'Under/Over 24th Period',
    1356: 'Under/Over 25th Period',
    1357: 'Under/Over 26th Period',
    1358: 'Under/Over 27th Period',
    1359: 'Under/Over 28th Period',
    1360: 'Under/Over 29th Period',
    1361: 'Under/Over 30th Period',
    1362: 'Under/Over 31st Period',
    1363: 'Under/Over 32nd Period',
    1364: 'Under/Over 33rd Period',
    1365: 'Under/Over 34th Period',
    1366: 'Under/Over 35th Period',
    1367: 'Asian Handicap 11th Period',
    1368: 'Asian Handicap 12th Period',
    1369: 'Asian Handicap 13th Period',
    1370: 'Asian Handicap 14th Period',
    1371: 'Asian Handicap 15th Period',
    1372: 'Asian Handicap 16th Period',
    1373: 'Asian Handicap 17th Period',
    1374: 'Asian Handicap 18th Period',
    1375: 'Asian Handicap 19th Period',
    1376: 'Asian Handicap 20th Period',
    1380: 'Asian Handicap 24th Period',
    1381: 'Asian Handicap 25th Period',
    1382: 'Asian Handicap 26th Period',
    1383: 'Asian Handicap 27th Period',
    1384: 'Asian Handicap 28th Period',
    1385: 'Asian Handicap 29th Period',
    1386: 'Asian Handicap 30th Period',
    1390: 'Asian Handicap 34th Period',
    1391: 'Asian Handicap 35th Period',
    1392: 'To Finish In Top 10',
    1393: 'To Stay Up',
    1394: 'Asian Handicap 10th Period',
    1395: 'Under/Over Player Powerplay Points',
    1396: 'Under/Over Player Plus/Minus',
    1397: 'Under/Over Player Shots',
    1398: 'Top Victorian Club',
    1399: 'Under/Over Run Outs',
    1400: 'Under/Over Frames',
    1401: 'Top 20 Winner',
    1402: '1st Round Leader',
    1404: '1st Round Six Shooter - Group A',
    1406: '1st Round 3 Balls',
    1407: '1st Round 18 Hole Match-Ups',
    1408: 'Match Handicap',
    1409: 'Team With Top Bowler',
    1410: 'To Win The Toss And The Match',
    1411: 'Under/Over Turrets Destroyed',
    1412: 'Under/Over Turrets Destroyed - 1st Period',
    1413: 'Under/Over Turrets Destroyed - 2nd Period',
    1414: 'Under/Over Turrets Destroyed - 3rd Period',
    1415: 'Highest Score After 1st Over',
    1416: 'Home Team Under/Over Runs - 1st Over to 6th Over',
    1417: 'Away Team Under/Over Runs - 1st Over to 6th Over',
    1418: 'Home Team Under/Over Runs - 1st Over to 8th Over',
    1419: 'Away Team Under/Over Runs - 1st Over to 8th Over',
    1420: 'Home Team Under/Over Runs - 1st Over to 10th Over',
    1421: 'Away Team Under/Over Runs - 1st Over to 10th Over',
    1422: 'To Chase Over 180 Runs And Team to Win',
    1423: 'How Many Runs Will Be Scored From The First Ball',
    1424: 'Under/Over Match Stumpings',
    1425: '1st Wicket Method',
    1426: 'First Ball Of The Match',
    1427: 'Under/Over - 1st Inning',
    1428: 'Top Non Victorian Club',
    1429: 'Grand Final Winner',
    1431: 'To Reach the Grand Final',
    1432: 'Not To Finish In Top 8',
    1434: 'Under/Over Kills 4th Period - Away Team',
    1435: 'Under/Over Kills 5th Period - Away Team',
    1436: 'Under/Over Kills 4th Period - Home Team',
    1437: 'Under/Over Kills 5th Period - Home Team',
    1438: 'Under/Over Temperature At Airport (Celsius)',
    1439: 'Asian Handicap - Full Time',
    1440: 'Under/Over Value Of All Numbers Drawn',
    1441: 'Under/Over Value Of All Odd Numbers Drawn',
    1442: 'Under/Over Value Of All Even Numbers Drawn',
    1443: 'More Even Are Drawn Than Odd Numbers',
    1444: 'Consecutive Numbers Will Be Drawn',
    1445: 'Under/Exactly/Over - Number Of Odd Numbered Balls Drawn',
    1446: 'Under/Exactly/Over - Number Of Even Numbered Balls Drawn',
    1447: 'Sum of 5 Bonus Balls',
    1448: 'First Bonus Ball Drawn - Odd/Even',
    1449: 'Last Bonus Ball Drawn - Odd/Even',
    1450: 'First Bonus Ball',
    1451: 'Last Bonus Ball',
    1452: 'The Lowest of 5 Bonus Balls',
    1453: 'Bonus Ball - Odd/Even',
    1454: 'Identical Numbers Will Be Drawn',
    1455: 'Highest Number Drawn - Odd/Even',
    1456: 'Lowest Number Drawn - Odd/Even',
    1457: 'Ball 1 - Under/Over',
    1458: 'Ball 1 - Odd/Even',
    1459: 'Ball 2 - Under/Over',
    1460: 'Ball 2 - Odd/Even',
    1461: 'Ball 3 - Under/Over',
    1462: 'Ball 3 - Odd/Even',
    1463: 'Ball 4 - Under/Over',
    1464: 'Ball 4 - Odd/Even',
    1465: 'Ball 5 - Under/Over',
    1466: 'Ball 5 - Odd/Even',
    1467: 'Ball 6 - Under/Over',
    1468: 'Ball 6 - Odd/Even',
    1469: 'Ball 7 - Under/Over',
    1470: 'Ball 7 - Odd/Even',
    1471: 'Ball 8 - Under/Over',
    1472: 'Ball 8 - Odd/Even',
    1473: 'Ball 9 - Under/Over',
    1474: 'Ball 9 - Odd/Even',
    1475: 'Ball 10 - Under/Over',
    1476: 'Ball 10 - Odd/Even',
    1477: 'Ball 11 - Under/Over',
    1478: 'Ball 11 - Odd/Even',
    1479: 'Ball 12 - Under/Over',
    1480: 'Ball 12 - Odd/Even',
    1481: 'Ball 13 - Under/Over',
    1482: 'Ball 13 - Odd/Even',
    1483: 'Ball 14 - Under/Over',
    1484: 'Ball 14 - Odd/Even',
    1485: 'Ball 15 - Under/Over',
    1486: 'Ball 15 - Odd/Even',
    1487: 'Ball 16 - Under/Over',
    1488: 'Ball 16 - Odd/Even',
    1489: 'Ball 17 - Under/Over',
    1490: 'Ball 17 - Odd/Even',
    1491: 'Ball 18 - Under/Over',
    1492: 'Ball 18 - Odd/Even',
    1493: 'Ball 19 - Under/Over',
    1494: 'Ball 19 - Odd/Even',
    1495: 'Ball 20 - Under/Over',
    1496: 'Ball 20 - Odd/Even',
    1497: 'At Least One Of The Numbers Drawn Is Divisible By 3',
    1498: 'At Least One Of The Numbers Drawn Is Divisible By 4',
    1499: 'At Least One Of The Numbers Drawn Is Divisible By 5',
    1500: 'At Least One Of The Numbers Drawn Is Divisible By 7',
    1501: 'At Least One Of The Numbers Drawn Is Divisible By 10',
    1502: 'At Least One Of The Numbers Drawn Is Divisible By 15',
    1503: 'At Least One Of The Numbers Drawn Is Divisible By 20',
    1504: 'Highest Number Drawn - Under/Over',
    1505: ' Total Value Of The Lowest And Highest Numbers Drawn - Under/Over',
    1506: 'Lowest Number Drawn - Under/Over',
    1507: 'Difference Between The Highest And The Lowest Numbers Drawn - Under/Over',
    1508: 'Total Value Of The Lowest And Highest Numbers Drawn - Odd/Even',
    1509: ' Difference Between The Highest And The Lowest Numbers Drawn - Odd/Even',
    1510: 'First Number Drawn Is Greater Than The Last Number Drawn',
    1511: 'Last Number Drawn - Odd/Even',
    1512: 'First Number Drawn - Odd/Even',
    1513: 'All Drawn Numbers Even',
    1514: 'All Drawn Numbers Odd',
    1515: 'Number To Draw',
    1520: '4th Period - 1st Turret',
    1521: '5th Period - 1st Turret',
    1523: 'Under/Over Barons Slain - 4th Period',
    1524: 'Under/Over Barons Slain - 5th Period',
    1525: 'Under/Over Dragons Slain - 4th Period',
    1526: 'Under/Over Dragons Slain - 5th Period',
    1527: 'Under/Over Turrets Destroyed - 4th Period',
    1528: 'Under/Over Turrets Destroyed - 5th Period',
    1529: '4th Period Duration',
    1530: '5th Period Duration',
    1531: 'Odd/Even Kills - 4th Period',
    1532: 'Odd/Even Kills - 5th Period',
    1533: 'Under/Over Inhibitors - 1st Period',
    1534: 'Under/Over Inhibitors - 2nd Period',
    1535: 'Temperature At Airport in Range (Celsius)',
    1536: 'Under/Over Maps',
    1537: 'Home Team To Win & Under/Over - Including Overtime',
    1538: 'Away Team To Win & Under/Over - Including Overtime',
    1539: 'Round Winner',
    1540: 'Total Match Legs Under/Over',
    1541: 'Rounds Handicap',
    1542: '1st Leg Winner',
    1543: 'First To Win 3 Legs',
    1546: '4th Period - 1st Roshan',
    1547: '5th Period - 1st Roshan',
    1548: '4th Period - 1st Barracks',
    1549: '5th Period - 1st Barracks',
    1550: '4th Period - 1st Tower',
    1551: '5th Period - 1st Tower',
    1552: 'Asian Under/Over Corners',
    1553: 'Both Teams To Score Number Of Points - 1st Period',
    1554: 'Both Teams To Score Number Of Points - 1st Half',
    1555: 'Under/Over 5th Period - Home Team',
    1556: 'Under/Over 5th Period - Away Team',
    1558: 'Asian Handicap Points',
    1559: 'Cup Winner',
    1561: '1st 3 Innings Winner',
    1562: '1st 3 Innings Under/Over',
    1563: 'Time Of Scoring',
    1564: 'Time Of Scoring - Home Team',
    1565: 'Time Of Scoring - Away Team',
    1566: 'Match Time Result',
    1567: 'Top Nationality - South African',
    1568: 'Top Nationality - Spaniard',
    1569: 'Top Nationality - Irish',
    1570: 'Top Nationality - South American',
    1571: 'Top Nationality - Canadian',
    1572: 'Top Nationality - Englishman',
    1573: 'Top Nationality - Continental European',
    1575: 'To Make Cut',
    1576: 'To Miss Cut',
    1577: 'Top Nationality - American',
    1578: 'Nationality Of Winner',
    1579: 'Asian Handicap Points - 1st Period',
    1580: 'Asian Handicap Points - 2nd Period',
    1581: 'Asian Handicap Points - 3rd Period',
    1583: 'Top Team Bowler 1st Inning - Home Team',
    1584: 'Top Team Bowler 1st Inning - Away Team',
    1585: 'Top Team Batsman 1st Inning - Home Team',
    1586: 'Top Team Batsman 1st Inning - Away Team',
    1587: 'Will there be an Ace Next Point ',
    1589: 'Will there be a Double Fault Next Point',
    1591: 'Under/Over - 1st Over',
    1592: 'To Win League',
    1593: 'To Win Division',
    1594: 'Most Hits - Including Overtime',
    1595: 'Fight to Go The Distance ',
    1596: 'When Will The Fight End',
    1597: 'Fight Outcome',
    1598: 'Round Betting',
    1599: 'Top 1st Hit',
    1600: 'Bottom 1st Hit',
    1601: 'Top 1st Run',
    1602: 'Bottom 1st Run',
    1603: 'Most Hits In The 1st Inning',
    1605: 'Team With Highest Scoring Inning',
    1606: 'Both Teams To Score 2 Or More Goals',
    1607: 'Both Teams To Score 3 Or More Goals',
    1608: 'Team To Win The Most Periods',
    1609: 'Asian Handicap Full Time - 1st Period',
    1610: 'Asian Handicap Full Time - 2nd Period',
    1611: 'Asian Handicap Full Time - 3rd Period',
    1612: 'MVP',
    1614: 'To Win Conference',
    1615: 'Winning Conference ',
    1617: 'Season Player Specials',
    1618: '1st 5 Innings Winner - 12',
    1619: '1st Period - Halftime Winner Home/Away ',
    1620: '1st Period - Halftime Asian Handicap',
    1621: '2nd Period - Halftime Winner Home/Away',
    1622: '2nd Period - Halftime Asian Handicap',
    1624: 'Under/Over Player Runs',
    1625: 'Under/Over Player Singles',
    1626: 'Under/Over Player Home Runs',
    1627: 'Under/Over Player Stolen Bases',
    1628: 'Under/Over Player Doubles',
    1629: 'Under/Over Player Triples',
    1630: 'Pitcher To Record The Win',
    1631: 'Under/Over Player Total Bases',
    1635: 'Under/Over Pitcher Runs Earned',
    1636: 'Under/Over Pitcher Hits Allowed',
    1637: 'Under/Over Pitcher Strikeouts',
    1638: 'Team Classification',
    1639: 'Young Rider Classification',
    1640: 'Points Classification',
    1641: 'King of the Mountains',
    1642: 'Stage 1',
    1649: 'Fastest Lap',
    1650: 'Winning Car',
    1651: 'First Driver to Retire',
    1652: ' Qualifying Winning Car',
    1653: 'Fastest in Practice 1',
    1654: 'Points Finish',
    1655: 'Fastest Qualifier',
    1657: 'Stage 3',
    1659: 'First Constructor Retirement',
    1660: 'Grid Position of Winner',
    1661: 'Safety Car Period During Race',
    1662: 'Number of Classified Drivers',
    1663: 'Virtual Safety Car Period During Race',
    1664: 'Hole Score',
    1667: 'To Win Hole',
    1670: 'Tied Match',
    1672: 'Under/Over Goals - 2nd Half',
    1673: 'Odd/Even Rounds 4th Period',
    1674: 'Odd/Even Rounds 5th Period',
    1675: '2nd Round Leader',
    1676: 'Both Teams to Score - Including Overtime',
    1677: 'Last Team to Score - Including Overtime',
    1678: 'Top Nationality - German',
    1679: 'Top Nationality - Austrian',
    1680: 'Top Nationality - New Zealander',
    1681: 'Top Left Handed Player',
    1682: 'Top Amateur',
    1683: 'Top Nationality - Korean',
    1684: 'Top Nationality - Scandinavian',
    1685: 'Top Nationality - Frenchman',
    1686: 'Top Former Winner',
    1687: 'Top Nationality - Swede',
    1688: 'Top Nationality - Japanese',
    1689: 'Top Nationality - Scotsman',
    1690: 'Top Nationality - Belgian',
    1691: ' European Handicap 4th Period',
    1692: 'European Handicap 3rd Period',
    1693: 'European Handicap 2nd Period',
    1694: 'European Handicap 1st Period',
    1695: 'Round Score',
    1696: 'Tournament Winning Score',
    1697: 'Leading Score After Round',
    1698: 'To Hit The Fairway With Tee Shot',
    1699: 'To Hit Green In Regulation',
    1700: '3rd Round Leader',
    1702: 'Under/Over Player Passing Attempts',
    1704: 'Touchdown on 1st Period',
    1705: 'Touchdown on 2nd Period',
    1706: 'Touchdown on 3rd Period',
    1707: 'Touchdown on 4th Period',
    1708: 'Under/Exactly/Over - Home Team Touchdowns ',
    1709: 'Under/Exactly/Over - Away Team Touchdowns ',
    1710: 'Under/Exactly/Over - Home Team 1st Half Touchdowns',
    1711: 'Under/Exactly/Over - Away Team 1st Half Touchdowns',
    1712: '16th Round Winner - 4th Period',
    1713: '16th Round Winner - 5th Period',
    1714: 'Under/Over Towers Destroyed - 4th Period',
    1715: 'Under/Over Towers Destroyed - 5th Period',
    1722: 'Set to Finish to Nil',
    1723: 'Under/Over Kills - 1st Period - 1st Round',
    1724: 'Under/Over Kills - 2nd Period - 1st Round',
    1728: 'Both Teams to Score - Extra Time',
    1729: 'Double Chance - Extra Time',
    1730: 'Will The Game Go To Super Over?',
    1731: 'Double Chance 5th Period',
    1732: 'Double Chance 6th Period',
    1733: 'Double Chance 7th Period',
    1734: 'Double Chance 8th Period',
    1736: 'Number of Runs 1st Inning',
    1737: 'Number of Runs 2nd Inning',
    1738: 'Number of Runs 3rd Inning',
    1739: 'Number of Runs 4th Inning',
    1740: 'Number of Runs 5th Inning',
    1741: 'Number of Runs 6th Inning',
    1742: 'Number of Runs 7th Inning',
    1743: 'Number of Runs 8th Inning',
    1744: 'A Fifty to be Scored in the 1st Innings',
    1745: 'Fall Of 1st Wicket',
    1747: 'Next Man Out',
    1748: 'Completed Match',
    1749: 'Century Scored',
    1750: 'Century Scored - 1st Inning',
    1752: 'Away Team First Dismissal 6-Way',
    1753: 'Top Match Batsman',
    1754: 'To Score a Penalty',
    1758: '6th Man of the Year',
    1760: 'Rookie of the Year',
    1761: 'Regular Season MVP',
    1763: 'Batsman to Score a Hundred in 1st Innings',
    1765: '9 Dart Finish in the match',
    1766: 'Time of First Try',
    1767: 'Time of Last Try',
    1770: '9 Dart Finish in the Match - Home Team ',
    1771: '9 Dart Finish in the Match - Away Team ',
    1772: 'Top Run Scorer',
    1773: 'Top Run Scorer - Home Team',
    1774: 'Top Run Scorer - Away Team',
    1775: 'Team of Top Run Scorer',
    1776: 'Under/Over Punts',
    1777: 'Under/Over Sacks',
    1778: 'Under/Over 1st Downs ',
    1779: '1st Team To Get A First Down',
    1780: '1st Half Under/Over Field Goals - Home Team',
    1781: '1st Half Under/Over Field Goals - Away Team',
    1782: 'Under/Exactly/Over - Field Goals 1st Half',
    1783: 'Under/Over Touchdowns 1st Period',
    1784: 'Under/Over Penalties Accepted',
    1785: 'Most Kills in the Match',
    1786: 'Team with Most Kills in the Match',
    1787: 'Under/Over Pass Attempts',
    1788: ' Under/Over Pass Completions',
    1789: 'Under/Over Passing Yards',
    1790: 'Under/Over Rush Attempts',
    1791: 'Under/Over Rush Yards',
    1792: 'Under/Over Player Tackles and Assist',
    1794: 'Team to Punt First',
    1795: '1st Period 1X2 And Under/Over',
    1796: '1X2 Assists',
    1797: 'Double Chance Assists',
    1798: 'Team to Lead After Every Period',
    1799: 'Team with Most Passing Yards',
    1800: 'Team with Most Rushing Yards',
    1801: 'Successful 2 Point Conversion',
    1802: 'Under/Over Touchdown Passes ',
    1803: 'Under/Over Field Goals 1st Period',
    1804: 'Score in Last 2 Minutes of the 1st Half',
    1805: 'Team to Receive Opening Kick Off',
    1806: 'Under/Over Home Team Touchdowns - 1st period',
    1807: 'Under/Over Away Team Touchdowns - 1st period',
    1808: 'Under/Exactly/Over Home Team Touchdowns - 1st period',
    1809: 'Under/Exactly/Over Away Team Touchdowns - 1st period',
    1810: 'Under/Over Home Team Field Goals - 1st period',
    1812: 'Under/Over Away Team Field Goals - 1st period',
    1813: 'Away Team to Win Every Quarter',
    1814: 'Home Team to Win Every Quarter',
    1815: 'First Offensive Play of the Game',
    1816: 'To Win Quarter',
    1817: 'To Reach the Final',
    1818: 'Home Team to Score 25 Goals',
    1819: 'Home Team to Score 30 Goals',
    1820: 'Away Team to Score 25 Goals',
    1821: 'Away Team to Score 30 Goals',
    1822: 'Home Team To Win Either Half',
    1823: 'Away Team To Win Either Half',
    1824: 'Stage of Elimination',
    1825: 'Under/Over Assists',
    1826: 'Under/Over Rebounds',
    1827: 'Under/Over Home team Assists',
    1828: 'Under/Over Away Team Assists',
    1829: 'Under/Over Home Team Rebounds',
    1830: 'Under/Over Away Team Rebounds',
    1831: '1X2 3 Points Made',
    1832: 'Under/Over 3 Points Made',
    1833: 'Under/Over Home Team 3 Points Made',
    1834: 'Under/Over Away Team 3 Points Made',
    1835: 'Asian Handicap 3 Points Made',
    1841: 'First Substitutions',
    1842: '2nd Goal in the match',
    1843: '3rd Goal in the match',
    1846: '60 Minute Result',
    1847: '75 Minute Result',
    1848: 'Goal Scored In The First 5 Minutes?',
    1849: 'First Throw-in in the Match',
    1850: 'Draw in Either Half',
    1851: 'Headed Goal in the Match?',
    1852: 'Under/Over Goal Minutes',
    1853: 'Under/Over Tie Break in the match',
    1854: 'Under/Over Break points',
    1855: 'Under/Over Break Points Home Team',
    1856: 'Under/Over Break Points Away Team',
    1857: 'Under/Over Highest Scoring Quarter',
    1858: 'Under/Over Lowest Scoring Quarter',
    1859: 'Most Tries 3-Way',
    1860: '10 Minute Winning Margin 5-Way',
    1861: 'Most Losses Team',
    1862: 'A Sub Will score in the Game',
    1863: 'Win All Periods - Home Team',
    1864: 'Win All Periods - Away Team',
    1865: 'First 10 Minute Under/Over Tries',
    1866: '15 Minute Under/Over - Corners',
    1867: '2 Goals in a Row by Team',
    1868: '3 Goals in a Row by Team',
    1869: 'Most Converted Tries ',
    1872: 'Unseeded Finalist?',
    1873: 'Wild Card To Reach Final?',
    1874: 'Winning Half - Tennis Outright',
    1875: 'Winning Quarter - Tennis Outright',
    1881: 'Hole In One',
    1882: 'Will There Be a Play-off?',
    1883: '72 Hole Match Ups',
    1884: '54 Hole Match Ups',
    1885: 'Number 1 Draft Pick',
    1886: '1X2 and Double Chance',
    1887: 'Home Team to Win\\Draw and Both teams to score',
    1888: 'Away Team to Win\\Draw and Both teams to score',
    1889: 'Winning Margin - 1st Half',
    1891: 'American League Cy Young',
    1892: 'American League MVP',
    1893: 'National League Cy Young',
    1894: 'National League MVP',
    1895: 'Pitcher To Record The Most Regular Season Wins',
    1896: 'Player To Hit The Most Regular Season Home Runs',
    1897: 'Will Game Decided in Extra Time?',
    1898: 'Regular season Winning %',
    1900: 'Player Top Goalscorer',
    1901: 'Under/Over Tackles',
    1902: 'Number 2 Draft Pick',
  },
};
