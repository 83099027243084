import { filter, uniqBy, map, orderBy, forEach } from 'lodash';
import { LIVE_CASINO_CATEGORIES, LIVE_CASINO_EVOLUTION_GAMES, VIRTUAL_SPORTS, categoriesOrder } from '../../config';
import * as Actions from '../actions/actionTypes';
import { Translate } from '../../localization';
import sortBy from 'lodash.sortby'

const initialState = {
    casinoGames: [],
    casinoCategories: [],
    filteredCasinoGames: [],
    loadingCasinoGames: true,
    activeCategory: '',
    currentGameData: {},
    liveCasinoGames: [],
    filteredLiveCasinoGames: [],
    liveCasinoCategories: [],
    loadingLiveCasinoGames: true,
    searchStarted: false,
    noSearchResults: false,
    activeCategoryLive: 'All',
    currentLiveGameData: {},
    virtualSports: [],
    filteredVirtualSports: [],
    virtualSportsCategories: [],
    loadingVirtualSports: true,
    virtualSportsSearchStarted: false,
    noVirtualSearchResult: false,
    virtualSportsActiveCategory: 'All',
    casinoGamesByCategory: [],
    mostPlayed: [],
    providers: [],
    loadingProviders: true,
    activeCategoryId: 0,
    activeProviderId: 0,
    activeProvider: '',
    casinoSearchedResults: [],
    newGames: [],
    casinoBanners: [],
    loadingBanners: true,
};

const casinoReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_CASINO_GAMES: {
            let games = [].concat.apply([], Object.values(action.games));
            let formattedGames = []
            let mostPlayed = []
            let newGames = []
            games.map((game) => {
                game.name = game.game_name || game.alias
                game.image = game.thumb
                formattedGames.push(game)
                if (game.hot == true) mostPlayed.push(game)
                if (game.is_new) newGames.push(game)
            })
            let gamesByCategory = action.games
            //spin2win and instant virtuals belong to same provider group
            let gamesCategoryKeys = Object.keys(gamesByCategory)
            if (gamesCategoryKeys.indexOf('SPIN2WIN') !== -1) {
                gamesByCategory['SPIN2WIN'] = [...gamesByCategory['SPIN2WIN']]
            }
            if (gamesCategoryKeys.indexOf('INSTANT_VIRTUALS') !== -1) {
                gamesByCategory['SPIN2WIN'] = [...gamesByCategory['INSTANT_VIRTUALS']]
                delete gamesByCategory['INSTANT_VIRTUALS']
            } 
            if (gamesCategoryKeys.indexOf('SPIN2WINMINES') !== -1) {
                gamesByCategory['SPIN2WIN'] = [...gamesByCategory['SPIN2WINMINES']]
                delete gamesByCategory['SPIN2WINMINES']
            }
            if (gamesCategoryKeys.indexOf('SPIN2WININSTANT') !== -1) {
                gamesByCategory['SPIN2WIN'] = [...gamesByCategory['SPIN2WININSTANT']]
                delete gamesByCategory['SPIN2WININSTANT']
            }
            // gamesByCategory['SPIN2WIN'] = [...gamesByCategory['SPIN2WIN'], ...gamesByCategory['INSTANT_VIRTUALS']]
            if (gamesCategoryKeys.indexOf('NETENT2') !== -1) {
                gamesByCategory['NETENT2'] = [...gamesByCategory['NETENT2']]

            }
            if (gamesCategoryKeys.indexOf('NETENT_MOBILE') !== -1) {
                gamesByCategory['NETENT2'] = [...gamesByCategory['NETENT_MOBILE']]
                delete gamesByCategory['NETENT_MOBILE']
            }
            // delete gamesByCategory['INSTANT_VIRTUALS']
            // gamesByCategory['NETENT2'] = [...gamesByCategory['NETENT2'], ...gamesByCategory['NETENT_MOBILE']]
            // delete gamesByCategory['NETENT_MOBILE']
            // let categories = []
            // Object.keys(action.games).map((item)=>
            // {
            //     categories.push({value: item})
            // }
            // );
            // categories.unshift({ value: Translate.all })
            let gamesSortedByPosition = sortBy(formattedGames, 'position')
            return {
                ...state,
                casinoGames: gamesSortedByPosition,
                // casinoCategories: categories,
                casinoGamesByCategory: gamesByCategory,
                loadingCasinoGames: false,
                mostPlayed: uniqBy(mostPlayed, "game_id"),
                newGames: newGames,
            };
        }

        case Actions.SET_LIVE_CASINO_GAMES: {
            let games = action.games;

            forEach(games, (game) => {
                if (game.category === 'CASINO/LIVECASINO/ROULETTE') {
                    game.sort = 1;
                } else if (game.category === 'CASINO/LIVECASINO/BLACKJACK') {
                    game.sort = 2;
                } else if (game.category === 'CASINO/LIVECASINO/BACCARAT') {
                    game.sort = 3;
                } else {
                    game.sort = 4;
                }
            });
            games = orderBy(games, ['sort'], 'asc');


            let liveCasinoCategories = uniqBy(map(games, 'category'));
            liveCasinoCategories =
                uniqBy(
                    map(liveCasinoCategories, (category) => {
                        const splitedCategory = category.split('/');
                        return {
                            value: splitedCategory[2],
                            name: splitedCategory[2]
                        };
                    }), 'value');
            liveCasinoCategories.unshift({
                value: Translate.all,
                name: Translate.all
            });

            return {
                ...state,
                liveCasinoGames: games,
                liveCasinoCategories: liveCasinoCategories,
                loadingLiveCasinoGames: false,
            };
        }

        case Actions.SET_CASINO_ACTIVE_CATEGORY: {
            let { category, id } = action.category
            return {
                ...state,
                activeCategory: category,
                activeCategoryId: id,
                loadingCasinoGames: true,
            };
        }

        case Actions.SET_CASINO_ACTIVE_PROVIDER: {
            let { provider, id } = action.provider
            return {
                ...state,
                activeProvider: provider,
                activeProviderId: id,
                activeCategoryId: 0,
                activeCategory: '',
                loadingCasinoGames: true,
            };
        }

        case Actions.SET_LIVE_CASINO_ACTIVE_CATEGORY: {
            let { liveCasinoGames } = state;

            if (action.category === Translate.all) {
                return {
                    ...state,
                    liveCasinoGames: liveCasinoGames,
                    filteredLiveCasinoGames: [],
                    activeCategoryLive: action.category,
                };
            } else {
                let filteredGames = filter(liveCasinoGames, (game) => {
                    return game.category.split('/')[2] === action.category;
                });
                return {
                    ...state,
                    filteredLiveCasinoGames: filteredGames,
                    activeCategoryLive: action.category,
                };
            }
        }

        case Actions.SET_CASINO_SEARCH_STARTED: {
            return {
                ...state,
                searchStarted: action.value,
                noSearchResults: false,
                casinoSearchedResults: [],
            };
        }

        case Actions.SET_LIVE_CASINO_SEARCH_STARTED: {
            return {
                ...state,
                searchStarted: action.value,
                filteredLiveCasinoGames: [],
            };
        }

        case Actions.SET_CASINO_SEARCH: {
            let { casinoGames } = state;
            if (action.value?.brand_id == 0) {
                let filteredGames = filter(casinoGames, (game) => {
                    let lowercaseGameName = game.name.toLowerCase();
                    let lowercaseSearchVal = action?.value?.searchVal?.toLowerCase();
                    return lowercaseGameName.includes(lowercaseSearchVal);
                });

                if (filteredGames.length > 0) {
                    return {
                        ...state,
                        casinoSearchedResults: uniqBy(filteredGames, "game_id"),
                        noSearchResults: false,
                    };
                } else {
                    return {
                        ...state,
                        casinoSearchedResults: [],
                        noSearchResults: true,
                    };
                }
            } else {
                let { games, brand_id } = action.value
                if (games.length) {
                    return {
                        ...state,
                        casinoSearchedResults: games,
                        noSearchResults: false,
                    };
                } else {
                    return {
                        ...state,
                        casinoSearchedResults: [],
                        noSearchResults: true,
                    };
                }
            }
        }

        case Actions.SET_LIVE_CASINO_SEARCH: {
            let { liveCasinoGames } = state;

            let filteredGames = filter(liveCasinoGames, (game) => {
                let lowercaseGameName = game.name.toLowerCase();
                let lowercaseSearchVal = action.value.toLowerCase();
                return lowercaseGameName.includes(lowercaseSearchVal);
            });

            if (filteredGames.length > 0) {
                return {
                    ...state,
                    filteredLiveCasinoGames: filteredGames,
                    noSearchResults: false,
                };
            } else {
                return {
                    ...state,
                    filteredLiveCasinoGames: [],
                    noSearchResults: true,
                };
            }
        }

        case Actions.SET_GAME_DATA: {
            return {
                ...state,
                currentGameData: action.data,
            };
        }

        case Actions.SET_LIVE_GAME_DATA: {
            return {
                ...state,
                currentLiveGameData: action.data,
            };
        }

        case Actions.CLEAR_CASINO_GAMES: {
            return {
                ...state,
                casinoGames: [],
                casinoCategories: [],
                filteredCasinoGames: [],
                loadingCasinoGames: true,
                searchStarted: false,
                noSearchResults: false,
                activeCategory: '',
                currentGameData: {},
                casinoGamesByCategory: [],
                mostPlayed: [],
                providers: [],
                newGames: [],
                activeCategoryId: 0,
                activeProviderId: 0,
                activeProvider: '',
            };
        }

        case Actions.CLEAR_LIVE_CASINO_GAMES: {
            return {
                ...state,
                liveCasinoGames: [],
                filteredLiveCasinoGames: [],
                liveCasinoCategories: [],
                loadingLiveCasinoGames: true,
                searchStarted: false,
                noSearchResults: false,
                activeCategoryLive: 'All',
                currentLiveGameData: {},
            };
        }

        case Actions.SET_VIRTUAL_SPORTS: {
            let sports = action.sports;

            forEach(sports, (sport) => {
                if (sport.category === 'CASINO/LIVECASINO/ROULETTE') {
                    sport.sort = 1;
                } else if (sport.category === 'CASINO/LIVECASINO/BLACKJACK') {
                    sport.sort = 2;
                    // } else if (sport.category === 'CASINO/LIVECASINO/BACCARAT') {
                    //     sport.sort = 3;
                } else {
                    sport.sort = 3;
                }
            });
            sports = orderBy(sports, ['sort'], 'asc');

            let virtualSportsCategories = VIRTUAL_SPORTS;
            return {
                ...state,
                virtualSports: sports,
                virtualSportsCategories: virtualSportsCategories,
                loadingVirtualSports: false,
            };
        }

        case Actions.SET_VIRTUAL_SPORTS_CATEGORY: {
            let { virtualSportsActiveCategory, virtualSports } = state;

            if (action.category === Translate.all) {
                return {
                    ...state,
                    virtualSportsActiveCategory: virtualSportsActiveCategory,
                    filteredVirtualSports: [],
                    virtualSportsActiveCategory: action.category,
                };
            } else {
                let filteredSports = filter(virtualSports, (sport) => {
                    return sport.category.split('/')[1] === action.category.split('/')[1];
                });
                return {
                    ...state,
                    filteredVirtualSports: filteredSports,
                    virtualSportsActiveCategory: action.category,
                };
            }
        }

        case Actions.CLEAR_VIRTUAL_SPORTS: {
            return {
                ...state,
                virtualSports: [],
                filteredVirtualSports: [],
                virtualSportsCategories: [],
                loadingVirtualSports: true,
                virtualSportsSearchStarted: false,
                noVirtualSearchResult: false,
                virtualSportsActiveCategory: 'All',
                currentVirtualSports: {},
            };
        }

        case Actions.SET_VIRTUAL_SPORTS_SEARCH: {
            let { virtualSports } = state;

            let filteredSports = filter(virtualSports, (sport) => {
                let lowercaseGameName = sport.name.toLowerCase();
                let lowercaseSearchVal = action.value.toLowerCase();
                return lowercaseGameName.includes(lowercaseSearchVal);
            });

            if (filteredSports.length > 0) {
                return {
                    ...state,
                    filteredVirtualSports: filteredSports,
                    noVirtualSearchResult: false,
                };
            } else {
                return {
                    ...state,
                    filteredVirtualSports: [],
                    noVirtualSearchResult: true,
                };
            }
        }

        case Actions.SET_VIRTUAL_SEARCH_STARTED: {
            return {
                ...state,
                virtualSportsSearchStarted: action.value
            }
        }

        case Actions.SET_CASINO_PROVIDERS: {
            let providerObj = action.value
            let providers = []
            providerObj.map((item) => {
                providers.push({ id: item.brand_id, value: item.brand_name })
            })
            return {
                ...state,
                providers: providers,
                loadingProviders: false
            }
        }

        case Actions.SET_CASINO_CATEGORIES: {
            let categoryObj = action.value
            let categories = []
            categoryObj.map((item) => {
                categories.push({ id: item?.category_id, value: item?.category_name, priority: categoriesOrder[item?.category_name] || '99'  })
            })
            categories.unshift({ id: 0, value: Translate.all, priority: 0 })
            let orderedCategories = sortBy(categories,'priority')
            return {
                ...state,
                casinoCategories: orderedCategories,
                activeCategory: orderedCategories[0].value,
                activeCategoryId: orderedCategories[0].id
            };
        }

        case Actions.SET_CASINO_BANNERS: {
            return {
                ...state,
                casinoBanners: action.value,
                loadingBanners: false,
            }
        }

        default:
            return state;
    }
};

export default casinoReducer;
