import * as Actions from '../actions/actionTypes';
import { differenceInMinutes } from 'date-fns';
import { ODD_SIZES } from '../../config';

let sessionStats = sessionStorage.getItem('stats_data');
if (sessionStats) sessionStats = JSON.parse(sessionStats);

let sessionStatsData = null;
// keep data only if it is fetched 30 min before
if (sessionStats?.date && differenceInMinutes(new Date(), new Date(`${sessionStats.date}`)) < 30) sessionStatsData = sessionStats.data;

const initialState = {
    loading: false,
    language: localStorage.getItem('language') ? localStorage.getItem('language') : 'fr',
    country: '',
    translation: null,
    statsData: sessionStatsData,
    isShowCasino: false,
    theme: localStorage.getItem('theme') || 'light-theme',
    showContent: '',
    oddsFontSize: localStorage.getItem('oddSize') || ODD_SIZES.normal,
    sportsUrl: '',
    loadingIframe: true,
    unique_id: ''
};

const generalReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_LOADING: {
            return {
                ...state,
                loading: action.value,
            };
        }

        case Actions.SHOW_CONTENT: {
            return {
                ...state,
                showContent: action.value,
            }
        }

        case Actions.SET_LANGUAGE: {
            return {
                ...state,
                language: action.value,
            };
        }

        case Actions.SET_COUNTRY_CODE: {
            return {
                ...state,
                country: action.countryCode,
            };
        }

        case Actions.SET_STATS_DATA: {
            return {
                ...state,
                statsData: action.data,
            };
        }
        case Actions.SET_TENET_STATUS: {
            return {
                ...state,
                isShowCasino: action.isShowCasino,
                //  isShowCasino: false,
            };

        }
        case Actions.SET_THEME: {
            return {
                ...state,
                theme: action.theme,
            };

        }

        case Actions.UPDATE_FONT_SIZE: {
            return {
                ...state,
                oddsFontSize: action.value
            };
        }

        case Actions.SET_SPORTS_URL: {
            return {
                ...state,
                sportsUrl: action.value,
                loadingIframe: false
            }
        }

        case Actions.STOP_LOADER: {
            return {
                ...state,
                loadingIframe: false
            }
        }

        case Actions.REMOVE_SPORTS_URL: {
            return {
                ...state,
                sportsUrl: '',
            }
        }

        case Actions.SET_UNIQUE_ID: {
            return {
                ...state,
                unique_id: action.payload,
            }
        }

        default:
            return state;
    }
};

export default generalReducer;
