import { Translate } from '../localization';

export const getTransactionTypes = () => {
    return [
        {
            label: Translate.all,
            value: 'all',
        },
        {
            label: Translate.deposit,
            value: 'deposit',
        },
        {
            label: Translate.withdraw,
            value: 'withdraw',
        },
        {
            label: Translate.credit,
            value: 'credet',
        },
        {
            label: Translate.debit,
            value: 'debet',
        },
        {
            label: Translate.pay,
            value: 'pay',
        }
    ];
};

export const getCoinPaymentTransactionTypes = () => {
    return [
        {
            label: Translate.all,
            value: 'all',
        },
        {
            label: Translate.deposit,
            value: 'deposit',
        },
        {
            label: Translate.withdraw,
            value: 'withdraw',
        },
    ];
};

export const getMyBetFilterTypes = () => {
    return [
        {
            label: Translate.all,
            value: 'all',
        },
        {
            label: `${Translate['in game']}`,
            value: 'in game',
        },
        {
            label: Translate.won,
            value: 'won',
        },
        {
            label: Translate.lost,
            value: 'lost',
        },
        {
            label: 'Refund',
            value: 'refund',
        },
        {
            label: 'Cashout',
            value: 'cashout',
        },
    ];
};
export const BET_TYPES = [
    {
        label: Translate.all,
        value: 'all',
    },
    {
        label: 'Bet',
        value: 'bet',
    },
    {
        label: 'Win',
        value: 'win',
    },
    {
        label: 'Lose',
        value: 'lose',
    }
]
export const CASINO_TYPES = [
    {
        label: Translate.all,
        value: 'all',
    },
    {
        label: 'Credit',
        value: 'credet',
    },
    {
        label: 'Debit',
        value: 'debet',
    }
];
export const LIVE_CASINO_TYPES = [
    {
        label: Translate.all,
        value: 'all',
    },
    {
        label: 'Credit',
        value: 'credet',
    },
    {
        label: 'Debit',
        value: 'debet',
    }
];

export const CASINO_CATEGORIES = [
    {
        id: 1,
        label: Translate.all,
        value: 'all',
    },
    {
        id: 2,
        label: 'Netent',
        value: 'netent',
    },
    {
        id: 3,
        label: 'Novomatic',
        value: 'novomatic',
    },
    {
        id: 4,
        label: 'One Touch',
        value: 'one-touch',
    },
    {
        id: 5,
        label: 'Pragmaticplay',
        value: 'pragmaticplay',
    },
    {
        id: 6,
        label: 'Amatic',
        value: 'amatic',
    },
    {
        id: 7,
        label: 'Betsoft',
        value: 'betsoft',
    },
    {
        id: 8,
        label: 'Netgame',
        value: 'netgame',
    },
    {
        id: 9,
        label: 'Egaming',
        value: 'egaming',
    },
    {
        id: 10,
        label: 'Wazdan',
        value: 'wazdan',
    },
    {
        id: 11,
        label: 'Boongo Games',
        value: 'boongo-games',
    },
    {
        id: 12,
        label: 'Spinomenal',
        value: 'spinomenal',
    },
    {
        id: 13,
        label: 'Virtual Generation',
        value: 'virtual-generation',
    },
    {
        id: 14,
        label: 'PGSoft',
        value: 'pgsoft',
    },
    {
        id: 15,
        label: 'Woohoo',
        value: 'woohoo',
    },
];

export const CASINO_TOP_GAMES = [1159335, 1002, 529, 1005, 507, 561, 1001, 570, 594, 137];

export const LIVE_CASINO_CATEGORIES = [
    {
        name: 'All',
        value: Translate.all,
        icon: 'icon-all',
    },
    {
        name: 'Roulette',
        value: 'CASINO/LIVECASINO/ROULETTE',
        icon: 'icon-roulette',
    },
    {
        name: 'Blackjack',
        value: 'CASINO/LIVECASINO/BLACKJACK',
        icon: 'icon-blackjack',
    },
    {
        name: 'Baccarat',
        value: 'CASINO/LIVECASINO/BACCARAT',
        icon: 'icon-baccarat',
    },
    {
        name: 'Dragon Tiger',
        value: 'CASINO/LIVECASINO/BACCARAT_DRAGON_TIGER',
        icon: 'icon-dragon-tiger',
    },
    {
        name: 'Poker',
        value: 'CASINO/LIVECASINO/POKER',
        icon: 'icon-poker',
    },
    {
        name: 'Lottery',
        value: 'CASINO/LIVECASINO/LOTTERY',
        icon: 'icon-bet-on-numbers',
    },
    {
        name: 'Holdem',
        value: 'CASINO/LIVECASINO/CASINO_HOLDEM',
        icon: '',
    },
    {
        name: 'Other',
        value: 'CASINO/LIVECASINO/OTHER',
        icon: '',
    },
    {
        name: 'Andar Bahar',
        value: 'CASINO/LIVECASINO/ANDAR_BAHAR',
        icon: '',
    },
    {
        name: 'Game Show',
        value: 'CASINO/LIVECASINO/GAME_SHOW',
        icon: '',
    },
    {
        name: 'Lucky 7',
        value: 'CASINO/LIVECASINO/LUCKY7',
        icon: '',
    }
];

export const VIRTUAL_SPORTS = [
    {
        name: 'All',
        value: Translate.all,
        icon: 'icon-all',
    },
    {
        name: 'Virtual Sports',
        value: 'CASINO/VIRTUAL_SPORTS/VIRTUAL_HORSES',
        icon: '',
    },
    {
        name: 'Virtual Games',
        value: 'CASINO/VIRTUALGAME/LOTTERY',
        icon: '',
    },
];

export const LIVE_CASINO_EVOLUTION_GAMES = [
    {
        category: 'Baccarat',
        company: 'New Evolution',
        name: 'First Person Baccarat',
        id: '1179673',
        logo: './images/live-casino/baccarat/Baccarat_first_person.jpg',
        sort: 4,
    },
    {
        category: 'Baccarat',
        company: 'New Evolution',
        name: 'Speed Baccarat G',
        id: '1179673',
        logo: './images/live-casino/baccarat/speed_baccarat.jpg',
        sort: 4,
    },
    {
        category: 'Blackjack',
        company: 'New Evolution',
        name: 'First Person Blackjack',
        id: '1179673',
        logo: './images/live-casino/blackjack/Black_jack_first_person.jpg',
        sort: 2,
    },
    {
        category: 'Blackjack',
        company: 'New Evolution',
        name: 'Blackjack Lobby',
        id: '1179673',
        logo: './images/live-casino/blackjack/Infonate_BlackJack.jpg',
        sort: 2,
    },
    {
        category: 'Blackjack',
        company: 'New Evolution',
        name: 'Blackjack Platinum VIP',
        id: '1179673',
        logo: './images/live-casino/blackjack/vip_black_jack.jpg',
        sort: 2,
    },
    {
        category: 'Dragon Tiger',
        company: 'New Evolution',
        name: 'Dragon Tiger',
        id: '1179673',
        logo: './images/live-casino/dragon-tiger/Dragon_Tiger.jpg',
        sort: 5,
    },
    {
        category: 'Football',
        company: 'New Evolution',
        name: 'Football studio',
        id: '1179673',
        logo: './images/live-casino/football-studio/Football_Studio.jpg',
        sort: 7,
    },
    {
        category: 'Holdem',
        company: 'New Evolution',
        name: 'Casino Holdem Lobby',
        id: '1179673',
        logo: './images/live-casino/holdem/Holdem.jpg',
        sort: 3,
    },
    {
        category: 'Holdem',
        company: 'New Evolution',
        name: 'Side Bet City',
        id: '1179673',
        logo: './images/live-casino/holdem/Side_Bet_city.jpg',
        sort: 3,
    },
    {
        category: 'Money Wheel',
        company: 'New Evolution',
        name: 'Dream Catcher',
        id: '1179673',
        logo: './images/live-casino/money-wheel/Dream_Catcher_Money_Wheel.jpg',
        sort: 8,
    },
    {
        category: 'Money Wheel',
        company: 'New Evolution',
        name: 'Dream Catcher',
        id: '1179673',
        logo: './images/live-casino/money-wheel/Money_Wheel_2.jpg',
        sort: 8,
    },
    {
        category: 'Roulette',
        company: 'New Evolution',
        name: 'American Roulette',
        id: '1179673',
        logo: './images/live-casino/roulette/AMERICAN_ROULETTE.jpg',
        sort: 1,
    },
    {
        category: 'Roulette',
        company: 'New Evolution',
        name: 'Auto-Roulette VIP',
        id: '1179673',
        logo: './images/live-casino/roulette/Auto_Roulette.jpg',
        sort: 1,
    },
    {
        category: 'Roulette',
        company: 'New Evolution',
        name: 'Immersive Roulette',
        id: '1179673',
        logo: './images/live-casino/roulette/Immersive_Roulette.jpg',
        sort: 1,
    },
    {
        category: 'Roulette',
        company: 'New Evolution',
        name: 'RNG Lightning Roulette',
        id: '1179673',
        logo: './images/live-casino/roulette/Lightning_Roulette.jpg',
        sort: 1,
    },
    {
        category: 'Roulette',
        company: 'New Evolution',
        name: 'Roulette',
        id: '1179673',
        logo: './images/live-casino/roulette/SPEED_ROULETTE.jpg',
        sort: 1,
    },
    {
        category: 'Roulette',
        company: 'New Evolution',
        name: 'Roulette',
        id: '1179673',
        logo: './images/live-casino/roulette/VIP_ROULETTE.jpg',
        sort: 1,
    },
    {
        category: 'Sick Bo',
        company: 'New Evolution',
        name: 'Super Sic Bo',
        id: '1179673',
        logo: './images/live-casino/sick-bo/Sick_Bo.jpg',
        sort: 9,
    },
    {
        category: 'Bet On Numbers',
        company: 'New Evolution',
        name: 'RNG First Person Mega Ball',
        id: '1179673',
        logo: './images/live-casino/megaball/Mega_Ball.jpg',
        sort: 6,
    },
];

export const intervalTime = 180000;

export const CASINO_PER_PAGE = 50;

export const sessionTimer = 5;

export const totalSegments = 45;

export const resultTotalSegments = 20;

export const prematchEventBatchSize = 100;

export const prematchMarketSize = 20;

export const depositPollingTimer = 10 * 60 * 1000;  // 10 minutes

export const depositPollingInterval = 5 * 1000; // 1 minute

export const maxWebsocketRetryCount = 5;

export const internationalLocationIds = [
    4, // International
    392, // International Youths
    393 // International Clubs
];

export const MAX_BET_COUNT = 20;

export const INNER_CONTENT = {
    'transactions': 'Transactions',
    'myBets': 'MyBets',
};

export const SPORT_LIST_PATH = {
    'prematch': 'prematch',
    'live': 'live',
    'results': 'results',
};

export const CASINO_VIEWS = [
    {
        view: 'grid-sm',
        icon: '/images/grid-16.png'
    },
    // {
    //     view: 'grid-md',
    //     icon: '/images/grid-9.png'
    // },
    {
        view: 'grid-lg',
        icon: '/images/grid-4.png'
    },
];

export const ODD_SIZES = {
    'normal': 'normal-odds',
    'small': 'small-odds',
    'large': 'large-odds',
};

export const currencyType = [
    {
        name: 'Bitcoin',
        value: 'BTC'
    },
    {
        name: 'Litecoin Testnet',
        value: 'LTCT'
    },
    {
        name: 'Bitcoin Cash',
        value: 'BCH'
    },
    {
        name: 'BlackCoin',
        value: 'BLK'
    },
    {
        name: 'Dash',
        value: 'DASH'
    },
    {
        name: 'Decred',
        value: 'DCR'
    },
    {
        name: 'DigiByte',
        value: 'DGB'
    },
    {
        name: 'Dogecoin',
        value: 'DOGE'
    },
    {
        name: 'Ether Classic',
        value: 'ETC'
    },
    {
        name: 'Ether',
        value: 'ETH'
    },
    {
        name: 'LISK',
        value: 'LSK'
    },
    {
        name: 'EOS',
        value: 'EOS'
    },
    {
        name: 'Expanse',
        value: 'EXP'
    },
    {
        name: 'Litecoin',
        value: 'LTC'
    },
    {
        name: 'GameCredits',
        value: 'GAME'
    },
    {
        name: 'MaidSafeCoin',
        value: 'MAID'
    },
    {
        name: 'NAV Coin',
        value: 'NAV'
    },
    {
        name: 'NEO',
        value: 'NEO'
    },
    {
        name: 'PotCoin',
        value: 'POT'
    },
    {
        name: 'Steem Dollars',
        value: 'SBD'
    },
    {
        name: 'STEEM',
        value: 'STEEM'
    },
    {
        name: 'Stratis',
        value: 'STRAT'
    },
    {
        name: 'Vertcoin',
        value: 'VTC'
    },
    {
        name: 'NEM',
        value: 'XEM'
    },
    {
        name: 'Monero',
        value: 'XMR'
    },
    {
        name: 'Ripple',
        value: 'XRP'
    },
    {
        name: 'VERGE',
        value: 'XVG'
    },
];

export const withdrawTransactionTypes = [
    {
        type: 'Online',
        value: 'online'
    },
    {
        type: 'Offline/Cash',
        value: 'offline'
    }
];

export const userCurrencies = [
    {
        name: 'EUR',
        value: 'EUR',
    },
    {
        name: 'TRY',
        value: 'TRY',
    },
    {
        name: 'RUB',
        value: 'RUB',
    }, {
        name: 'HTG',
        value: 'HTG',
    }, {
        name: 'CFA',
        value: 'CFA',
    }, {
        name: 'XAF',
        value: 'XAF',
    }, {
        name: 'TND',
        value: 'TND',
    }, {
        name: 'SSP',
        value: 'SSP',
    }, {
        name: 'USD',
        value: 'USD',
    }, {
        name: 'ETB',
        value: 'ETB',
    },
    {
        name: 'GBP',
        value: 'GBP',
    },
    {
        name: 'NAD',
        value: 'NAD',
    },
    {
        name: 'INR',
        value: 'INR',
    },
];

export const getHomeTableColumnCount = () => {
    const fixedColumn = 5;
    const screenResolution = [767,990, 1380, 1520, 1800, 2100];
    const homeTableColumnResponsive = {  // min-width: column
        [screenResolution[0]]: 1,
        [screenResolution[1]]: fixedColumn + 2,
        [screenResolution[2]]: fixedColumn + 4,
        [screenResolution[3]]: fixedColumn + 6,
        [screenResolution[4]]: fixedColumn + 8,
        [screenResolution[5]]: fixedColumn + 10,
    };

    const screenWidth = window.screen.availWidth;

    if (window.matchMedia(`(min-width: ${screenResolution[5] + 1}px)`)?.matches) return homeTableColumnResponsive[screenResolution[5]];
    else if (window.matchMedia(`(min-width: ${screenResolution[4] + 1}px)`)?.matches) return homeTableColumnResponsive[screenResolution[4]];
    else if (window.matchMedia(`(min-width: ${screenResolution[3] + 1}px)`)?.matches) return homeTableColumnResponsive[screenResolution[3]];
    else if (window.matchMedia(`(min-width: ${screenResolution[2] + 1}px)`)?.matches) return homeTableColumnResponsive[screenResolution[2]];
    else if (window.matchMedia(`(min-width: ${screenResolution[1] + 1}px)`)?.matches) return homeTableColumnResponsive[screenResolution[1]];

    else return homeTableColumnResponsive[screenResolution[0]];
};

export const SORT_BY = {
    COMPETITION: 'competition',
    TIME: 'time',
};


export const CountryCodes = {
    'Andorra': 'AD',
    'United Arab Emirates': 'AE',
    'Afghanistan': 'AF',
    'Antigua & Barbuda': 'AG',
    'Anguilla': 'AI',
    'Albania': 'AL',
    'Armenia': 'AM',
    'Angola': 'AO',
    'Argentina': 'AR',
    'American Samoa': 'AS',
    'Austria': 'AT',
    'Australia': 'AU',
    'Aruba': 'AW',
    'Åland Islands': 'AX',
    'Azerbaijan': 'AZ',
    'Bosnia & Herzegovina': 'BA',
    'Barbados': 'BB',
    'Bangladesh': 'BD',
    'Belgium': 'BE',
    'Burkina Faso': 'BF',
    'Bulgaria': 'BG',
    'Bahrain': 'BH',
    'Burundi': 'BI',
    'Benin': 'BJ',
    'St. Barthélemy': 'BL',
    'Bermuda': 'BM',
    'Brunei': 'BN',
    'Bolivia': 'BO',
    'Brazil': 'BR',
    'Bahamas': 'BS',
    'Bhutan': 'BT',
    'Bouvet Island': 'BV',
    'Botswana': 'BW',
    'Belarus': 'BY',
    'Belize': 'BZ',
    'Canada': 'CA',
    'Cocos (Keeling) Islands': 'CC',
    'Congo - Kinshasa': 'CD',
    'Central African Republic': 'CF',
    'Congo - Brazzaville': 'CG',
    'Switzerland': 'CH',
    'Côte d’Ivoire': 'CI',
    'Cook Islands': 'CK',
    'Chile': 'CL',
    'Cameroon': 'CM',
    'China': 'CN',
    'Colombia': 'CO',
    'Costa Rica': 'CR',
    'Cuba': 'CU',
    'Cape Verde': 'CV',
    'Curaçao': 'CW',
    'Christmas Island': 'CX',
    'Cyprus': 'CY',
    'Czech Republic': 'CZ',
    'Germany': 'DE',
    'Germany Amateur': 'DE',
    'Djibouti': 'DJ',
    'Denmark': 'DK',
    'Dominica': 'DM',
    'Dominican Republic': 'DO',
    'Algeria': 'DZ',
    'Ecuador': 'EC',
    'Estonia': 'EE',
    'Egypt': 'EG',
    'Eritrea': 'ER',
    'Spain': 'ES',
    'Ethiopia': 'ET',
    'European Union': 'EU',
    'Finland': 'FI',
    'Fiji': 'FJ',
    'Falkland Islands': 'FK',
    'Micronesia': 'FM',
    'Faroe Islands': 'FO',
    'France': 'FR',
    'Gabon': 'GA',
    'United Kingdom': 'GB',
    'Grenada': 'GD',
    'Georgia': 'GE',
    'French Guiana': 'GF',
    'Guernsey': 'GG',
    'Ghana': 'GH',
    'Gibraltar': 'GI',
    'Greenland': 'GL',
    'Gambia': 'GM',
    'Guinea': 'GN',
    'Guadeloupe': 'GP',
    'Equatorial Guinea': 'GQ',
    'Greece': 'GR',
    'So. Georgia & So. Sandwich Isl.': 'GS',
    'Guatemala': 'GT',
    'Guam': 'GU',
    'Guinea-Bissau': 'GW',
    'Guyana': 'GY',
    'Hong Kong (China)': 'HK',
    'Heard & McDonald Islands': 'HM',
    'Honduras': 'HN',
    'Croatia': 'HR',
    'Haiti': 'HT',
    'Hungary': 'HU',
    'Indonesia': 'ID',
    'Ireland': 'IE',
    'Israel': 'IL',
    'Isle of Man': 'IM',
    'India': 'IN',
    'British Indian Ocean Territory': 'IO',
    'Iraq': 'IQ',
    'Iran': 'IR',
    'Iceland': 'IS',
    'Italy': 'IT',
    'Jersey': 'JE',
    'Jamaica': 'JM',
    'Jordan': 'JO',
    'Japan': 'JP',
    'Kenya': 'KE',
    'Kyrgyzstan': 'KG',
    'Cambodia': 'KH',
    'Kiribati': 'KI',
    'Comoros': 'KM',
    'St. Kitts & Nevis': 'KN',
    'North Korea': 'KP',
    'South Korea': 'KR',
    'Kuwait': 'KW',
    'Cayman Islands': 'KY',
    'Kazakhstan': 'KZ',
    'Laos': 'LA',
    'Lebanon': 'LB',
    'St. Lucia': 'LC',
    'Liechtenstein': 'LI',
    'Sri Lanka': 'LK',
    'Liberia': 'LR',
    'Lesotho': 'LS',
    'Lithuania': 'LT',
    'Luxembourg': 'LU',
    'Latvia': 'LV',
    'Libya': 'LY',
    'Morocco': 'MA',
    'Monaco': 'MC',
    'Moldova': 'MD',
    'Montenegro': 'ME',
    'St. Martin': 'MF',
    'Madagascar': 'MG',
    'Marshall Islands': 'MH',
    'Macedonia': 'MK',
    'Mali': 'ML',
    'Myanmar (Burma)': 'MM',
    'Mongolia': 'MN',
    'Macau (China)': 'MO',
    'Northern Mariana Islands': 'MP',
    'Martinique': 'MQ',
    'Mauritania': 'MR',
    'Montserrat': 'MS',
    'Malta': 'MT',
    'Mauritius': 'MU',
    'Maldives': 'MV',
    'Malawi': 'MW',
    'Mexico': 'MX',
    'Malaysia': 'MY',
    'Mozambique': 'MZ',
    'Namibia': 'NA',
    'New Caledonia': 'NC',
    'Niger': 'NE',
    'Norfolk Island': 'NF',
    'Nigeria': 'NG',
    'Nicaragua': 'NI',
    'Netherlands': 'NL',
    'Norway': 'NO',
    'Nepal': 'NP',
    'Nauru': 'NR',
    'Niue': 'NU',
    'New Zealand': 'NZ',
    'Oman': 'OM',
    'Panama': 'PA',
    'Peru': 'PE',
    'French Polynesia': 'PF',
    'Papua New Guinea': 'PG',
    'Philippines': 'PH',
    'Pakistan': 'PK',
    'Poland': 'PL',
    'St. Pierre & Miquelon': 'PM',
    'Pitcairn Islands': 'PN',
    'Puerto Rico': 'PR',
    'Palestinian Territories': 'PS',
    'Portugal': 'PT',
    'Palau': 'PW',
    'Paraguay': 'PY',
    'Qatar': 'QA',
    'Réunion': 'RE',
    'Romania': 'RO',
    'Serbia': 'RS',
    'Russia': 'RU',
    'Rwanda': 'RW',
    'Saudi Arabia': 'SA',
    'Solomon Islands': 'SB',
    'Seychelles': 'SC',
    'Sudan': 'SD',
    'Sweden': 'SE',
    'Singapore': 'SG',
    'St. Helena': 'SH',
    'Slovenia': 'SI',
    'Svalbard & Jan Mayen': 'SJ',
    'Slovakia': 'SK',
    'Sierra Leone': 'SL',
    'San Marino': 'SM',
    'Senegal': 'SN',
    'Somalia': 'SO',
    'Suriname': 'SR',
    'South Sudan': 'SS',
    'São Tomé & Príncipe': 'ST',
    'El Salvador': 'SV',
    'Sint Maarten': 'SX',
    'Syria': 'SY',
    'Swaziland': 'SZ',
    'Turks & Caicos Islands': 'TC',
    'Chad': 'TD',
    'French Southern Territories': 'TF',
    'Togo': 'TG',
    'Thailand': 'TH',
    'Tajikistan': 'TJ',
    'Tokelau': 'TK',
    'Timor-Leste': 'TL',
    'Turkmenistan': 'TM',
    'Tunisia': 'TN',
    'Tonga': 'TO',
    'Turkey': 'TR',
    'Trinidad & Tobago': 'TT',
    'Tuvalu': 'TV',
    'Taiwan': 'TW',
    'Tanzania': 'TZ',
    'Ukraine': 'UA',
    'Uganda': 'UG',
    'U.S. Outlying Islands': 'UM',
    'United States': 'US',
    'Uruguay': 'UY',
    'Uzbekistan': 'UZ',
    'Vatican City': 'VA',
    'St. Vincent & Grenadines': 'VC',
    'Venezuela': 'VE',
    'British Virgin Islands': 'VG',
    'U.S. Virgin Islands': 'VI',
    'Vietnam': 'VN',
    'Vanuatu': 'VU',
    'Wallis & Futuna': 'WF',
    'Samoa': 'WS',
    'Kosovo': 'XK',
    'Yemen': 'YE',
    'Mayotte': 'YT',
    'South Africa': 'ZA',
    'Zambia': 'ZM',
    'Zimbabwe': 'ZW',
    'England': 'GB-ENG',
    'Northern Ireland': 'GB-NIR',
    'Scotland': 'GB-SCT',
    'Wales': 'GB-WLS',
    'Shetland': 'GB-ZET',
    'Pride': 'LGBT',
    'California': 'US-CA',
};

export const categoriesOrder = {
    All: 0,
    Slot: 1,
    Poker: 2,
    Virtuals: 3,
    Instant: 4,
    Tables: 5,
    Scratch: 6,
    Fish: 7,
    Keno: 8,
}