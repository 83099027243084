export const SET_LOADING = '[APP] SET LOADING';
export const SET_LANGUAGE = '[APP] SET LANGUAGE';
export const SET_COUNTRY_CODE = '[APP] SET COUNTRY CODE';
export const GET_TRANSLATION = '[APP] GET TRANSLATION';
export const SET_TRANSLATION = '[APP] SET TRANSLATION';
export const SET_STATS_DATA = '[APP] SET_STATS_DATA';
export const SET_TENET_STATUS = '[APP] SET_TENET_STATUS';
export const SET_THEME = '[APP] SET THEME';
export const SHOW_CONTENT = '[APP] SHOW CONTENT';
export const UPDATE_FONT_SIZE = '[APP] UPDATE FONT SIZE';
export const SET_SPORTS_URL = '[APP] SET SPORTS URL';
export const STOP_LOADER = '[APP] STOP_LOADER';
export const REMOVE_SPORTS_URL = '[APP] REMOVE SPORTS URL';
export const SET_UNIQUE_ID = '[APP] SET UNIQUE ID';