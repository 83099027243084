export const GET_USER = '[APP] GET USER';
export const SET_USER = '[APP] SET USER';
export const SUBMIT_LOGIN = '[APP] SUBMIT LOGIN';
export const LOGOUT_USER = '[APP] LOGOUT USER';
export const LOGIN_SUCCESS = '[APP] LOGIN SUCCESS';
export const SIGNUP_ERROR = '[APP] SIGNUP ERROR';
export const LOGIN_ERROR = '[APP] LOGIN ERROR';
export const CHANGE_PASSWORD_SUCCESS = '[APP] CHANGE PASSWORD SUCCESS';
export const CHANGE_PASSWORD_ERROR = '[APP] CHANGE PASSWORD ERROR';
export const SET_CASHBACK_DATA = '[APP] SET CASHBACK DATA';
export const SET_CASHBACK_SUCESS = '[APP] SET CASHBACK SUCESS';
export const DEPOSIT_AMOUNT = '[APP] DEPOSIT AMOUNT';
export const RESET_DEPOSIT_AMOUNT = '[APP] RESET DEPOSIT AMOUNT';
export const SET_ODD_TYPE = '[APP] SET ODD TYPE';
export const OPEN_OTP_FORM_SIGNUP = '[APP] OPEN_OTP_FORM_SIGNUP';
export const OPEN_OTP_FORM_LOGIN = '[APP] OPEN_OTP_FROM_LOGIN';
export const USER_DATA = '[APP] USER_DATA';
export const GET_OTP_ERROR = '[APP] GET_OTP_ERROR';
export const OPEN_OTP_FORM_WITHDRAW = '[APP] OPEN_OTP_FORM_WITHDRAW';
export const GET_WITHDRAW_OTP_ERROR = '[APP] GET_WITHDRAW_OTP_ERROR';
export const SET_AMOUNT_MONCASHNUMBER = '[APP] SET_AMOUNT_MONCASHNUMBER';
export const SUCCESS_DEPOSIT = '[APP] SUCCESS_DEPOSIT';
export const SUCCESS_WITHDRAW = '[APP] SUCCESS_WITHDRAW';
export const SET_TRANSACTION_LOADING = '[APP] SET_TRANSACTION_LOADING';
export const SET_TRANSACTION_DETAIL = '[APP] SET_TRANSACTION_DETAIL';
export const SET_TRANSACTIONS_STATUS_ERROR = '[APP] SET_TRANSACTIONS_STATUS_ERROR'
export const SET_USER_DETAILS = '[APP] SET_USER_DETAILS';
export const SET_FORGET_ERROR = '[APP] SET_FORGET_ERROR'
