export const ON_TODAY_SPORT_SELECTED = '[APP] ON TODAY SPORT SELECTED';
export const SET_TODAY_SEARCH = '[APP] SET TODAY SEARCH';
export const ON_TODAY_SEARCH = '[APP] ON TODAY SEARCH';
export const SET_TODAY_SEARCH_STARTED = '[APP] SET TODAY SEARCH STARTED';
export const ON_TODAY_MAIN_MARKET_SELECTED = '[APP] ON TODAY MAIN MARKET SELECTED';
export const ON_TODAY_REMOVE_LEAGUE = '[APP] ON TODAY REMOVE LEAGUE';
export const SET_TODAY_SPORTS = '[APP] SET TODAY SPORTS';
export const SET_TODAY_NOEVENT = '[APP} SET TODAY NO EVENT';
export const SET_TODAY_SPORT_EVENTS = '[APP] SET TODAY SPORT EVENTS';
export const SET_TODAY_SPORT_LOCATIONS = '[APP] SET TODAY SPORT LOCATIONS';
export const SET_TODAY_SPORT_LOCATIONS_LOADING = '[APP] SET TODAY SPORT LOCATIONS LOADING';
export const SET_TODAY_LOCATION_EVENTS = '[APP] SET TODAY LOCATION EVENTS';
export const SET_TODAY_SELECTED_LOCATIONS = '[APP] SET TODAY SELECTED LOCATIONS';
export const CLEAR_TODAY_LOCATIONS = '[APP] CLEAR TODAY LOCATIONS';
export const CLEAR_TODAY_SPORT_EVENTS = '[APP] CLEAR TODAY SPORT EVENTS';
export const ON_TODAY_NO_SEARCH_RESULTS = '[APP] ON TODAY NO SEARCH RESULTS';
export const SET_TODAY_SEARCH_LOAD_MORE = '[APP] SET TODAY SEARCH LOAD MORE';
export const ON_CLEAR_TODAY_SEARCH = '[APP] ON CLEAR TODAY SEARCH';
export const ON_TODAY_RESET_PAGE = '[APP] ON TODAY RESET PAGE';
export const UPDATE_TODAY_EVENTS_MARKET = '[APP] UPDATE TODAY EVENTS MARKET';
export const UPDATE_TODAY_EVENTS_STATUS = '[APP] UPDATE TODAY EVENTS STATUS';
export const SEARCH_TODAY_EVENTS = '[APP] SEARCH TODAY EVENTS';
export const SET_TODAY_EVENTS_MARKETS = '[APP] SET TODAY EVENTS MARKETS';
export const SET_TODAY_DATE_FILTER = '[APP] SET TODAY DATE FILTER';
export const SET_TODAY_PARTIAL_LOCATIONS = '[APP] SET TODAY PARTIAL LOCATIONS';
export const SET_TODAY_LOCATIONS_OBJECT = '[APP] SET TODAY LOCATIONS OBJECT';
export const CLEAR_TODAY_PARTIAL_LOCATIONS = '[APP] CLEAR TODAY PARTIAL LOCATION';
export const SET_TODAY_SPORT_LEAGUES = '[APP] SET TODAY SPORT LEAGUES';
export const CLEAR_TODAY_LEAGUES = '[APP] CLEAR TODAY LEAGUES';
export const SET_UPDATED_MATCH_DATA = '[APP] SET UPDATED MATCH DATA';
export const ON_CLEAR_REMOTE_SEARCH = '[APP] ON CLEAR REMOTE SEARCH';
export const SET_TODAY_HAS_NEXT_PAGE = '[APP] SET TODAY HAS NEXT PAGE';
export const SET_TODAY_PARTIAL_SEARCH_RESULTS = '[APP] SET TODAY PARTIAL SEARCH RESULTS';
export const SET_REMOTE_TODAY_SEARCH = '[APP] SET REMOTE TODAY SEARCH';
export const SET_TODAY_LEAGUE_EVENTS = '[APP] SET TODAY LEAGUE EVENTS';
export const SET_ALL_MATCHES_ACTIVE = '[APP] SET ALL MATCHES ACTIVE';
export const SET_TODAY_LEAGUES_OBJECT = '[APP] SET TODAY LEAGUES OBJECT';
export const SELECT_MARKET_FILTERS = '[APP] SELECT MARKET FILTERS';
export const SET_PARTIAL_EVENTS = '[APP] SET PARTIAL EVENTS';
export const SET_SEARCHED_EVENTS = '[APP] SET SEARCHED EVENTS';
export const SET_ACTIVE_SPORT = '[APP] SET_ACTIVE_SPORT';