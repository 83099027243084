export const ON_INPLAY_SPORT_SELECTED = '[APP] ON INPLAY SPORT SELECTED';
export const SET_INPLAY_SEARCH = '[APP] SET INPLAY SEARCH';
export const ON_INPLAY_SEARCH = '[APP] ON INPLAY SEARCH';
export const SET_INPLAY_SEARCH_STARTED = '[APP] SET INPLAY SEARCH STARTED';
export const ON_INPLAY_MAIN_MARKET_SELECTED = '[APP] ON INPLAY MAIN MARKET SELECTED';
export const ON_INPLAY_REMOVE_LEAGUE = '[APP] ON INPLAY REMOVE LEAGUE';
export const SET_INPLAY_SPORTS = '[APP] SET INPLAY SPORTS';
export const SET_INPLAY_SPORT_EVENTS = '[APP] SET INPLAY SPORT EVENTS';
export const SET_INPLAY_SPORT_LOCATIONS = '[APP] SET INPLAY SPORT LOCATIONS';
export const SET_INPLAY_LOCATION_EVENTS = '[APP] SET INPLAY LOCATION EVENTS';
export const SET_INPLAY_SELECTED_LOCATIONS = '[APP] SET INPLAY SELECTED LOCATIONS';
export const CLEAR_INPLAY_LOCATIONS = '[APP] CLEAR INPLAY LOCATIONS';
export const CLEAR_SPORT_EVENTS = '[APP] CLEAR SPORT EVENTS';
export const ON_INPLAY_NO_SEARCH_RESULTS = '[APP] ON INPLAY NO SEARCH RESULTS';
export const SET_INPLAY_SEARCH_LOAD_MORE = '[APP] SET INPLAY SEARCH LOAD MORE';
export const ON_CLEAR_INPLAY_SEARCH = '[APP] ON CLEAR INPLAY SEARCH';
export const ON_INPLAY_RESET_PAGE = '[APP] ON INPLAY RESET PAGE';
export const SET_INPLAY_EVENTS_STATUS = '[APP] SET INPLAY EVENTS STATUS';
export const SET_INPLAY_HAS_NEXT_PAGE = '[APP] SET INPLAY HAS NEXT PAGE';
export const UPDATE_INPLAY_EVENTS_MARKET = '[APP] UPDATE INPLAY EVENTS MARKET';
export const UPDATE_INPLAY_EVENTS_LIVESCORE = '[APP] UPDATE INPLAY EVENTS LIVESCORE';
export const UPDATE_INPLAY_EVENTS_STATUS = '[APP] UPDATE INPLAY EVENTS STATUS';
export const ON_INPLAY_CURRENT_EVENT_COUNT = '[APP] ON INPLAY CURRENT EVENT COUNT';
export const ON_INPLAY_EVENT_COUNT = '[APP] ON INPLAY EVENT COUNT';
export const RESET_LIVE_MATCH = '[APP] RESET LIVE MATCH';
export const SET_INPLAY_LOADING = '[APP] SET INPLAY LOADING';
export const SET_INPLAY_SPORT_COUNT = '[APP] SET INPLAY SPORT COUNT'
export const SET_INPLAY_LEAGUES_LIST = '[APP] SET INPLAY LEAGUES LIST';
export const SET_INPLAY_LEAGUE_EVENTS = '[APP] SET INPLAY LEAGUE EVENTS';
export const SET_ACTIVE_LEAGUE_ID = '[APP] SET ACTIVE LEAGUE ID'
