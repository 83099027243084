const BASE_API = process.env.REACT_APP_API_URL;
const API_URL = process.env.REACT_APP_API_URL + '/';
const ADMIN_BASE = process.env.REACT_APP_ADMIN_API_URL;
const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL + '/api/v0/';
const CASINO_URL = process.env.REACT_APP_API_URL;

export const apiConfig = {
    routes: {
        login: API_URL + 'login',
        logout: API_URL + 'logout',
        signup: API_URL + 'signup',
        getotp: API_URL + 'get-otp',
        verifyOtp: API_URL + 'verify-otp',
        changePassword: API_URL + 'change-password',
        updateProfile: API_URL + 'update-profile',
        uploadProfilePicture: API_URL + 'upload_profile_image',
        forgetPassword: API_URL + 'forgot-password',
        user: API_URL + 'players',
        cashback: API_URL + 'cashback',
        getMostPlayed: API_URL + 'most-played-fixtures',
        // deposit: BASE_API + '/payment-record',
        deposit: API_URL + 'coin_payment_create_transaction',
        // withdraw : BASE_API +'/withdraw',
        withdraw: API_URL + 'coin_payment_withdraw',
        withdrawOffline: API_URL + 'withdraw-request',
        placeBet: API_URL + 'create-betslip',
        getBetslips: API_URL + 'betslip-list',
        getSingleBetslip: API_URL + 'bet-list',
        getLastBetslip: API_URL + 'last-bet-slip',
        getBetLimits: API_URL + 'get-bet-limits',
        getTransactions: API_URL + 'bets/transactions', //old endpoint
        getSportsTransactions: API_URL + 'iframe_transaction', //for iframe sportsbook
        transactionStatus: API_URL + 'get_transaction_status',
        getCasinoTransactions: API_URL + 'casino-transactions',
        getLiveCasinoTransactions: API_URL + 'live-casino-transactions',
        coinPaymentTransactions: API_URL + 'coinpayments-transactions',
        bonusTransactions: API_URL + 'bonus-transactions',
        refreshToken: API_URL + 'refresh',
        getCasinoGames: CASINO_URL + '/spintowincasino/gamelist',
        initCasinoUser: API_URL + 'casino/initiate-user',
        tenetCasinoStatus: API_URL + 'spintowin_casino_status',
        getFooters: ADMIN_BASE + '/get-footers/',
        getPromotion: ADMIN_API_URL + 'users/promotions/',
        getSocialLinks: BASE_API + '/get-social-url',
        getGameData: API_URL + 'casino/game',
        getCasinoProviders: API_URL + 'spintowincasino/providers',
        getCasinoCategories: API_URL + 'spintowincasino/categories',
        searchCasino: API_URL + 'spintowincasino/search',
        getCasinoBanners: ADMIN_API_URL + 'users/banners/',
        // getLiveCasinoGames: CASINO_URL + 'live-casino-game-list/',
        getLiveCasinoGames: CASINO_URL + '/game/list_custom',
        getLiveGameData: CASINO_URL + 'single-game',
        placeLiveBet: API_URL + 'place-live-bets',

        getDeviceLocation: 'https://get.geojs.io/v1/ip/country.json',

        getCashoutData: API_URL + 'betslip/get_cashout_amount',
        processCashout: API_URL + 'betslip/process_cashout_amount',
        casinoAPI: CASINO_URL + '/spintowincasino/gameurl',
        getLiveStreamData: API_URL + 'live-stream-events',
        getBetslipPayout: API_URL + 'pay-betslip-amount',

        // Virtual sports
        getVirtualSports: CASINO_URL + '/game/list_custom',

        // to search events at sports page
        searchEvent: API_URL + 'search_events',

        // get stats data
        getStatsScore: 'https://api.statscore.com/v2/booked-events?client_id=549&product=prematchcenter&lang=en&mapped_status=mapped&events_details=no',

        // getPrematchCount: 'https://rxqe53o1sd.execute-api.eu-west-2.amazonaws.com/beta'
        getPrematchCount: 'https://fvbg10pe89.execute-api.us-east-1.amazonaws.com/prod',
        // getPrematchCount: 'https://badmin.igamingbook.com/api/v0/pulls/prematch_sport_data',
        getActiveSports: API_URL + 'get_active_sports',

        //sports iframe integration
        getSportsUrl: API_URL + "get-sportbook-url",

        //get tenant details
        getTenantDetails : API_URL + "get-project-id"
    },
};
