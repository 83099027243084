export const SET_PREMATCH_LEAGUE_EVENTS = '[APP] SET PREMATCH LEAGUE EVENTS';
export const SET_PREMATCH_SEARCH_STARTED = '[APP] SET PREMATCH SEARCH STARTED';
export const SET_PREMATCH_SEARCH = '[APP] SET PREMATCH SEARCH';
export const ON_SEARCH = '[APP] ON SEARCH';
export const ON_CLEAR_SEARCH = '[APP] ON CLEAR SEARCH';
export const ON_MAIN_MARKET_SELECTED = '[APP] ON MAIN MARKET SELECTED';
export const ON_SPORT_SELECTED = '[APP] ON SPORT SELECTED';
export const ON_LOCATION_SELECTED = '[APP] ON LOCATION SELECTED';
export const SET_PREMATCH_DATE_FILTER = '[APP] SET PREMATCH DATE FILTER';
export const RESET_PREMATCH = '[APP] RESET PREMATCH';
export const ON_LEAGUE_SELECTED = '[APP] ON LEAGUE SELECTED';
export const SET_LOCATIONS_ACTIVE = '[APP] SET LOCATIONS ACTIVE';
export const SET_PREMATCH_ACTIVE = '[APP] SET PREMATCH ACTIVE';
export const ON_PREMATCH_REMOVE_LEAGUE = '[APP] ON PREMATCH REMOVE LEAGUE';
export const SET_PREMATCH_SPORTS = '[APP] SET PREMATCH SPORTS';
export const SET_PREMATCH_LOCATIONS = '[APP] SET PREMATCH LOCATIONS';
export const CLEAR_PREMATCH_LOCATIONS = '[APP] CLEAR PREMATCH LOCATIONS';
export const CLEAR_LEAGUE_EVENTS = '[APP] CLEAR LEAGUE EVENTS';
export const ON_PREMATCH_NO_SEARCH_RESULTS = '[APP] ON PREMATCH NO SEARCH RESULTS';
export const ON_PREMATCH_RESET_PAGE = '[APP] ON PREMATCH RESET PAGE';
export const SET_PREMATCH_SEARCH_LOAD_MORE = '[APP] SET PREMATCH SEARCH LOAD MORE';
export const SET_PREMATCH_SEARCH_STATUS = '[APP] SET PREMATCH SEARCH STATUS';
export const SET_PREMATCH_HAS_NEXT_PAGE = '[APP] SET PREMATCH HAS NEXT PAGE';
export const UPDATE_PREMATCH_EVENTS_MARKET = '[APP] UPDATE PREMATCH EVENTS MARKET';
export const UPDATE_PREMATCH_EVENTS_LIVESCORE = '[APP] UPDATE PREMATCH EVENTS LIVESCORE';
export const UPDATE_PREMATCH_EVENTS_STATUS = '[APP] UPDATE PREMATCH EVENTS STATUS';
export const SET_PREMATCH_LEAGUE_FOR_LOCATION = '[APP] SET PREMATCH LEAGUE FOR LOCATION';
export const SET_PREMATCH_LOCACTIONS_OBJECT = '[APP] SET PREMATCH LOCATIONS OBJECT';
export const SET_PREMATCH_PARTIAL_LOCATIONS = '[APP] SET PREMATCH PARTIAL LOCATIONS';
export const SET_PREMATCH_ALL_EVENTS = '[APP] SET PREMATCH  ALL EVENTS';
export const SET_PARTIAL_LEAGUE_DATA = '[APP] SET PARTIAL LEAGUE DATA';
export const SET_PREMATCH_CLEAR_LOCATIONS = '[APP] SET PREMATCH CLEAR LOCATIONS';
export const SET_PREMATCH_SPORTS_COUNT_OBJECT = '[APP] SET PREMATCH SPORTS COUNT OBJECT';
export const SET_PREMATCH_PARTIAL_SEARCH_RESULTS = '[APP] SET PREMATCH PARTIAL SEARCH RESULTS';