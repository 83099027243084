import { en } from './en';
import { ht } from './ht';
import { tr } from './tr';
import { de } from './de';
import { nl } from './nl';
import { fr } from './fr';
import { it } from './it';

import LocalizedStrings from 'react-localization';

export const Translate = new LocalizedStrings({
    en: en,
    tr: tr,
    de: de,
    nl: nl,
    fr: fr,
    hr: ht,
    it: it,
});
