import React, { Suspense, lazy, useState } from 'react';
import LoadingIcon from './Components/Common/LoadingIcon';
import * as AWS from 'aws-sdk';
import Amplify from 'aws-amplify';
import Util from './helper/Util';

const DesktopRoutes = lazy(() => import('./Routes/desktopRoutes'));
const MobileRoutes = lazy(() => import('./Routes/mobileRoutes'));

const isMobileOnly = Util.isMobileCheck()


// const awsmobile =  {
//     'aws_appsync_graphqlEndpoint': Util.getValues(process.env.REACT_APP_JETFIRE),
//     'aws_appsync_region': process.env.REACT_APP_AWS_REGION,
//     'aws_appsync_authenticationType': 'API_KEY',
//     'aws_appsync_apiKey': Util.getValues(process.env.REACT_APP_WARPATH),
// };

// Amplify.configure(awsmobile);

// AWS.config.update({
//     region: process.env.REACT_APP_AWS_REGION,
//     endpoint: `dynamodb.${process.env.REACT_APP_AWS_REGION}.amazonaws.com`,
//     accessKeyId: Util.getValues(process.env.REACT_APP_MEGATRON),
//     secretAccessKey: Util.getValues(process.env.REACT_APP_IRONHIDE)
// });

const dynamodb = new AWS.DynamoDB();
const dynamoClient = new AWS.DynamoDB.DocumentClient();

function App(props) {
    // return <button onClick={methodDoesNotExist}>Bad Button</button>;
    return (
        <div className='main-wrap'>
            <Suspense fallback={<LoadingIcon theme='dark centered' />}>
                 <DesktopRoutes isMobileOnly={isMobileOnly} {...props} />
            </Suspense>
        </div>

    );
}

export default App;
export { dynamoClient };
