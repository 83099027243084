export const ON_EXTRA_MARKET_SELECTED = '[APP] ON EXTRA MARKET SELECTED';
export const SET_EXTRA_MARKETS = '[APP] SET EXTRA MARKETS';
export const CLEAR_EXTRA_MARKETS = '[APP] CLEAR EXTRA MARKETS';
export const SET_CURRENT_EVENT = '[APP] SET CURRENT EVENT';
export const SET_STATISTICS = '[APP] SET STATISTICS';
export const CLEAR_STATISTICS = '[APP] CLEAR STATISTICS';
export const UPDATE_EXTRA_MARKETS_EVENTS_MARKET = '[APP] UPDATE EXTRA MARKETS EVENTS MARKET';
export const UPDATE_EXTRA_MARKETS_EVENTS_LIVESCORE = '[APP] UPDATE EXTRA MARKETS EVENTS LIVESCORE';
export const SET_EXTRA_MARKET_LOCATION = '[APP] SET EXTRA MARKET LOCATION';
export const SET_EXTRA_MARKET_LEAGUE_NAME = 'SET EXTRA MARKET LEAGUE NAME';
export const SET_EXTRA_MARKET_LOCATION_NAME = 'SET EXTRA MARKET LOCATION NAME';
export const SET_REPLACE_HEADER = '[APP] SET REPLACE HEADER';