export const SET_FAVORITES = '[APP] SET FAVORITES';
export const SET_FAVORITES_LIVE = '[APP] SET FAVORITES LIVE';
export const SET_FAVORITES_ERROR = '[APP] SET FAVORITES ERROR';

export const UPDATE_FAVORITES_EVENTS_MARKET = '[APP] UPDATE FAVORITES EVENTS MARKET';
export const UPDATE_FAVORITES_EVENTS_LIVESCORE = '[APP] UPDATE FAVORITES EVENTS LIVESCORE';
export const UPDATE_FAVORITES_EVENTS_STATUS = '[APP] UPDATE FAVORITES EVENTS STATUS';

export const ADD_FAVORITE = '[APP] ADD FAVORITE';
export const REMOVE_FAVORITE = '[APP] REMOVE FAVORITE';
