
const sportIds = {
    football: 1,
    basketball: 2,
    icehockey:4,
    tennis: 5,
    handball: 6,
    americanFootball: 16
}

export const FOOTBALL_MARKETS_FILTERS_PREMATCH = {
  marketGroups: ['Final Result', 'Totals', '1st Half', '2nd Half', 'Score', 'Corners'],

  main_markets_id_list: {
    marketName: 'Final Result',
    markets: [
      { Id: '1', Name: '1x2', col: ['1', 'X', '2'] },
      { Id: '18', Name: 'Total', col: ['', 'Over', 'Under'], isLine: true },
      { Id: '10', Name: 'Double Chance', col: ['1X', '12', 'X2'] },
      { Id: '26', Name: 'Odd/Even', col: ['Odd', 'Even'] },
      { Id: '14', Name: 'Handicap', col: ['1', 'X', '2'] },
  ]
  },

  first_half_market_id_list: {
      marketName: '1st Half',
      markets: [
        { Id: '60', Name: '1st half 1x2', col: ['1', 'X', '2'] }, //1st half 1X2
        { Id: '68', Name: '1st half Total', col: ['', 'Over', 'Under'], isLine: true },
        { Id: '63', Name: '1st half Double Chance', col: ['1X', '12', 'X2'] },
        { Id: '74', Name: '1st half Odd/Even', col: ['Odd', 'Even'] },
        { Id: '66', Name: '1st half Handicap', col: ['1', 'X', '2'] },
    ]
  },

  second_half_market_id_list: {
      marketName: '2nd Half',
      markets: [
        { Id: '83', Name: '2nd half 1x2', col: ['1', 'X', '2'] }, // 2nd half 1X2
        { Id: '90', Name: '2nd half Total', col: ['', 'Over', 'Under'], isLine: true }, // 2nd half total
        { Id: '85', Name: '2nd half Double Chance', col: ['1X', '12', 'X2'] },
        { Id: '94', Name: '2nd half Odd/Even', col: ['Odd', 'Even'] },
        { Id: '87', Name: '2nd half Handicap', col: ['1', 'X', '2'] },
    ]
  },

  score_market_id_list: {
      marketName: 'Score',
      markets: [
        { Id: '29', Name: 'Both Teams To Score', col: ['Yes', 'No'] },
        { Id: '75', Name: '1st half Both Teams To Score', col: ['Yes', 'No'] },
        { Id: '95', Name: '2nd half Both Teams To Score', col: ['Yes', 'No'] },
        { Id: '56', Name: 'Home - to score in both', col: ['Yes', 'No']},
        { Id: '57', Name: 'Away - to score in both', col: ['Yes', 'No']},
    ]
  },

  corners_market_id_list: {
      marketName: 'Corners',
      markets: [
        { Id: '162', Name: 'Corner 1x2', col: ['1', 'X', '2'] },
        { Id: '165', Name: 'Corner Handicap', col: ['1', 'X', '2'] },
        { Id: '166', Name: 'Total Corners', col: ['', 'Over', 'Under'], isLine: true },
        { Id: '164', Name: 'Last Corner', col: ['1', 'None', '2'] },
        { Id: '172', Name: 'Odd/Even corner', col: ['Odd', 'Even'] },
    ]
    },

  totals_id_list: {
      marketName: 'Totals',
      markets: [
        { Id: '18', Name: 'Total - Final', col: ['', 'Over', 'Under'], isLine: true },
        { Id: '68', Name: 'Total - 1st half', col: ['', 'Over', 'Under'], isLine: true },
        { Id: '90', Name: 'Total - 2nd half', col: ['', 'Over', 'Under'], isLine: true },
        { Id: '19', Name: 'Total - Home', col: ['', 'Over', 'Under'], isLine: true },
        { Id: '20', Name: 'Total - Away', col: ['', 'Over', 'Under'], isLine: true },
    ]
   },
};

export const TENNIS_MARKETS_FILTERS_PREMATCH = {
  marketGroups: ['Final Result', 'Totals', 'Set', 'Handicap'],

  main_markets_id_list: {
    marketName: 'Final Result',
    markets: [
      { Id: '186', Name: 'Winner', col: ['1', '2'] },
      { Id: '189', Name: 'Total Games', col: ['', 'Over', 'Under'], isLine: true },
      { Id: '192', Name: 'Odd/Even Games', col: ['Odd', 'Even'] },
      { Id: '187', Name: 'Game Handicap', col: ['', '1', '2'], isLine: true },
      { Id: '188', Name: 'Set Handicap', col: ['', '1', '2'], isLine: true },
  ]
  },

  totals_id_list: {
    marketName: 'Totals',
    markets: [
      { Id: '189', Name: 'Total Games', col: ['', 'Over', 'Under'], isLine: true },
      { Id: '190', Name: 'Home Total Games', col: ['', 'Over', 'Under'], isLine: true },
      { Id: '191', Name: 'Away Total Games', col: ['', 'Over', 'Under'], isLine: true },
      { Id: '204', Name: 'Set Total Games', col: ['', 'Over', 'Under'], isLine: true },
  ]
 },

  set_market_id_list: {
      marketName: 'Set',
      markets: [
        { Id: '202', Name: 'Set - Winner', col: ['1', '2'] },
        { Id: '204', Name: 'Set - Total Games', col: ['', 'Over', 'Under'], isLine: true },
        { Id: '203', Name: 'Set - Game Handicap', col: ['', '1', '2'], isLine: true },
    ]
  },

  handicap_market_id_list: {
      marketName: 'Handicap',
      markets: [
        { Id: '187', Name: 'Game Handicap', col: ['', '1', '2'], isLine: true },
        { Id: '188', Name: 'Set Handicap', col: ['', '1', '2'], isLine: true },
        { Id: '203', Name: 'Set - Game Handicap', col: ['', '1', '2'], isLine: true },
    ]
  },
};

export const BASKETBALL_MARKETS_FILTERS_PREMATCH = {
  marketGroups: ['Final Result', 'Totals', '1st Half', '2nd Half', 'Incl. Overtime'],

  main_markets_id_list: {
    marketName: 'Final Result',
    markets: [
      { Id: '1', Name: '1x2', col: ['1', 'X', '2'] },
      { Id: '18', Name: 'Total', col: ['', 'Over', 'Under'], isLine: true },
      { Id: '219', Name: 'Winner incl overtime', col: ['1', '2'] },
      { Id: '14', Name: 'Handicap', col: ['1', 'X', '2'] },
      { Id: '11', Name: 'Draw no bet', col: ['1', '2'] },
  ]
  },

  first_half_market_id_list: {
      marketName: '1st Half',
      markets: [
        { Id: '60', Name: '1st half 1x2', col: ['1', 'X', '2'] }, //1st half 1X2
        { Id: '64', Name: '1st half Draw no bet', col: ['1', '2'] },
        { Id: '68', Name: '1st half Total', col: ['', 'Over', 'Under'], isLine: true },
        { Id: '69', Name: '1st half Home Total', col: ['', 'Over', 'Under'], isLine: true },
        { Id: '70', Name: '1st half Away Total', col: ['', 'Over', 'Under'], isLine: true },
    ]
  },

  second_half_market_id_list: {
      marketName: '2nd Half',
      markets: [
        { Id: '83', Name: '2nd half 1x2', col: ['1', 'X', '2'] }, // 2nd half 1X2
        { Id: '66', Name: '2nd half Draw no bet', col: ['1', '2'] },
        { Id: '90', Name: '2nd half total', col: ['', 'Over', 'Under'], isLine: true }, // 2nd half total
        { Id: '91', Name: '2nd half Home total', col: ['', 'Over', 'Under'], isLine: true },
        { Id: '92', Name: '2nd half Away total', col: ['', 'Over', 'Under'], isLine: true },
    ]
  },

  incl_overtime_market_id_list: {
      marketName: 'Incl. Overtime',
      markets: [
        { Id: '219', Name: 'Winner incl overtime', col: ['1', '2'] },
        { Id: '225', Name: 'Total incl overtime', col: ['', 'Over', 'Under'], isLine: true },
        { Id: '227', Name: 'Home - Total incl overtime', col: ['', 'Over', 'Under'], isLine: true },
        { Id: '228', Name: 'Away - Total incl overtime', col: ['', 'Over', 'Under'], isLine: true },
        { Id: '223', Name: 'Handicap incl overtime', col: ['', '1', '2'], isLine: true },
    ]
  },

  totals_id_list: {
      marketName: 'Totals',
      markets: [
        { Id: '18', Name: 'Total - Final', col: ['', 'Over', 'Under'], isLine: true },
        { Id: '68', Name: 'Total - 1st half', col: ['', 'Over', 'Under'], isLine: true },
        { Id: '90', Name: 'Total - 2nd half', col: ['', 'Over', 'Under'], isLine: true },
        { Id: '19', Name: 'Total - Home', col: ['', 'Over', 'Under'], isLine: true },
        { Id: '20', Name: 'Total - Away', col: ['', 'Over', 'Under'], isLine: true },
    ]
   },
};

export const ICEHOCKEY_MARKETS_FILTERS_PREMATCH = {
  marketGroups: ['Final Result', 'Totals', 'Incl. Overtime'],

  main_markets_id_list: {
    marketName: 'Final Result',
    markets: [
      { Id: '1', Name: '1x2', col: ['1', 'X', '2'] },
      { Id: '18', Name: 'Totals', col: ['', 'Over', 'Under'], isLine: true },
      { Id: '10', Name: 'Double Chance', col: ['1X', '12', 'X2'] },
      { Id: '26', Name: 'Odd/Even', col: ['Odd', 'Even'] },
      { Id: '14', Name: 'Handicap', col: ['1', 'X', '2'] },
  ]
  },

  totals_id_list: {
      marketName: 'Totals',
      markets: [
        { Id: '18', Name: 'Total - Final', col: ['', 'Over', 'Under'], isLine: true },
        { Id: '19', Name: 'Total - Home', col: ['', 'Over', 'Under'], isLine: true },
        { Id: '20', Name: 'Total - Away', col: ['', 'Over', 'Under'], isLine: true },
    ]
   },

  incl_overtime_market_id_list: {
    marketName: 'Incl. Overtime',
    markets: [
      { Id: '406', Name: 'Winner incl overtime and penalties', col: ['1', '2'] },
      { Id: '412', Name: 'Total incl overtime and penalties', col: ['', 'Over', 'Under'], isLine: true },
      { Id: '406', Name: 'Handicap incl overtime and penalties', col: ['', '1', '2'], isLine: true },
  ]
},
};

export const HANDBALL_MARKETS_FILTERS_PREMATCH = {
  marketGroups: ['Final Result', 'Totals', '1st Half', '2nd Half'],

  main_markets_id_list: {
    marketName: 'Final Result',
    markets: [
      { Id: '1', Name: '1x2', col: ['1', 'X', '2'] },
      { Id: '18', Name: 'Total', col: ['', 'Over', 'Under'], isLine: true },
      { Id: '10', Name: 'Double Chance', col: ['1X', '12', 'X2'] },
      { Id: '26', Name: 'Odd/Even', col: ['Odd', 'Even'] },
      { Id: '11', Name: 'Draw no bet', col: ['1', '2'] },
  ]
  },

  first_half_market_id_list: {
      marketName: '1st Half',
      markets: [
        { Id: '60', Name: '1st half 1x2', col: ['1', 'X', '2'] }, //1st half 1X2
        { Id: '68', Name: '1st half Total', col: ['', 'Over', 'Under'], isLine: true },
        { Id: '63', Name: '1st half Double Chance', col: ['1X', '12', 'X2'] },
        { Id: '74', Name: '1st half Odd/Even', col: ['Odd', 'Even'] },
        { Id: '66', Name: '1st half Handicap', col: ['1', 'X', '2'] },
    ]
  },

  second_half_market_id_list: {
      marketName: '2nd Half',
      markets: [
        { Id: '83', Name: '2nd half 1x2', col: ['1', 'X', '2'] }, // 2nd half 1X2
        { Id: '90', Name: '2nd half Total', col: ['', 'Over', 'Under'], isLine: true }, // 2nd half total
        { Id: '85', Name: '2nd half Double Chance', col: ['1X', '12', 'X2'] },
        { Id: '94', Name: '2nd half Odd/Even', col: ['Odd', 'Even'] },
        { Id: '87', Name: '2nd half Handicap', col: ['1', 'X', '2'] },
    ]
  },

  totals_id_list: {
      marketName: 'Totals',
      markets: [
        { Id: '18', Name: 'Total - Final', col: ['', 'Over', 'Under'], isLine: true },
        { Id: '68', Name: 'Total - 1st half', col: ['', 'Over', 'Under'], isLine: true },
        { Id: '90', Name: 'Total - 2nd half', col: ['', 'Over', 'Under'], isLine: true },
        { Id: '19', Name: 'Total - Home', col: ['', 'Over', 'Under'], isLine: true },
        { Id: '20', Name: 'Total - Away', col: ['', 'Over', 'Under'], isLine: true },
    ]
   },
};


export const FOOTBALL_MARKETS_FILTERS_LIVE = {
    marketGroups: ['Final Result', 'Totals', '1st Half', '2nd Half', 'Score', 'Corners'],
  
    main_markets_id_list: {
      marketName: 'Final Result',
      markets: [
        { Id: '2_0', Name: '1x2', col: ['1', 'X', '2'] },
        { Id: '5_0', Name: 'Total', col: ['', 'Over', 'Under'], isLine: true },
        { Id: '8_27', Name: 'Double Chance', col: ['1X', '12', 'X2'] },
        { Id: '6_13', Name: 'Next Goal', col: ['1', 'X', '2'] },
        { Id: '4_0', Name: 'Handicap', col: ['1', 'X', '2'] },
    ]
    },
  
    first_half_market_id_list: {
        marketName: '1st Half',
        markets: [
          { Id: '6_22', Name: '1x2', col: ['1', 'X', '2'] }, //1st half 1X2
          { Id: '7_21', Name: 'Total', col: ['', 'Over', 'Under'], isLine: true },
          { Id: '8_325', Name: 'Double Chance', col: ['1X', '12', 'X2'] },
          { Id: '6_107', Name: 'Next Goal', col: ['1', 'X', '2'] },
          { Id: '6_101', Name: 'Handicap', col: ['1', 'X', '2'] },
      ]
    },
  
    second_half_market_id_list: {
        marketName: '2nd Half',
        markets: [
            { Id: '6_285', Name: '1x2', col: ['1', 'X', '2'] }, //1st half 1X2
            { Id: '8_287', Name: 'Double Chance', col: ['1X', '12', 'X2'] },
            { Id: '8_1667', Name: 'Home team to score', col: [ 'Over', 'Exactly', 'Under'] },
            { Id: '8_1668', Name: 'Away team to score', col:  [ 'Over', 'Exactly', 'Under'] },
            { Id: '8_303', Name: 'Both team to score', col: [ 'Yes', 'No'] },
        ]
    },
  
    score_market_id_list: {
        marketName: 'Score',
        markets: [
          { Id: '8_303', Name: 'Both team to score', col: [ 'Yes', 'No'] },
          { Id: '8_1527', Name: '2nd half Both team to score', col: [ 'Yes', 'No'] },
          { Id: '8_1671', Name: 'Home team to score in both', col: ['Yes', 'No'] },
          { Id: '8_1672', Name: 'Away team to score in both', col: ['Yes', 'No'] },
      ]
    },
  
    corners_market_id_list: {
        marketName: 'Corners',
        markets: [
          { Id: '6_120', Name: 'Corner Bet', col: ['1', 'X', '2'] },
          { Id: '8_126', Name: 'Total Corners', col: ['', 'Over', 'Under'], isLine: true },
          { Id: '6_2008', Name: '1st half Corner Bet', col: ['Over', 'Exactly', 'Under'] },
          { Id: '6_2009', Name: '2nd half Corner Bet', col: ['Over', 'Exactly', 'Under'] },
          { Id: '8_2007', Name: 'Match Corners', col: ['Over', 'Exactly', 'Under'] },
      ]
      },
  
    totals_id_list: {
        marketName: 'Totals',
        markets: [
          { Id: '5_0', Name: 'Total - Match', col: ['', 'Over', 'Under'], isLine: true },
          { Id: '7_21', Name: 'Total - 1st half', col: ['', 'Over', 'Under'], isLine: true },
          { Id: '8_142', Name: 'Total - Home', col: ['', 'Over', 'Under'], isLine: true },
          { Id: '8_143', Name: 'Total - Away', col: ['', 'Over', 'Under'], isLine: true },
          { Id: '8_126', Name: 'Total Corners', col: ['', 'Over', 'Under'], isLine: true },
      ]
     },
};

export const TENNIS_MARKETS_FILTERS_LIVE = {
  marketGroups: ['Final Result'],

  main_markets_id_list: {
    marketName: 'Final Result',
    markets: [
      { Id: '7_10', Name: 'Winner', col: ['1', '2'] },
      { Id: '8_83', Name: 'Total Games', col: ['', 'Over', 'Under'], isLine: true },
      { Id: '7_11', Name: 'Set Winner', col: ['1', '2'] },
      { Id: '8_94', Name: 'Number of Games', col: ['Odd', 'Even'] },
      { Id: '7_922', Name: 'Handicap', col: ['', '1', '2'], isLine: true },
  ]
  },
}

export const BASKETBALL_MARKETS_FILTERS_LIVE = {
  marketGroups: ['Final Result'],

  main_markets_id_list: {
    marketName: 'Final Result',
    markets: [
      { Id: '2_0', Name: '1X2', col: ['1', 'X', '2'] },
      { Id: '8_39', Name: 'Total (Incl. OT)', col: ['', 'Over', 'Under'], isLine: true },
      { Id: '7_37', Name: 'Winner (Incl. OT)', col: ['1', '2'] },
      { Id: '8_974', Name: 'Odd/Even', col: ['Odd', 'Even'] },
      { Id: '4_0', Name: 'Handicap', col: ['1', 'X', '2'] },
  ]
  },
}

export const ICEHOCKEY_MARKETS_FILTERS_LIVE = {
  marketGroups: ['Final Result'],

  main_markets_id_list: {
    marketName: 'Final Result',
    markets: [
      { Id: '2_0', Name: '1x2', col: ['1', 'X', '2'] },
      { Id: '5_0', Name: 'Total', col: ['', 'Over', 'Under'], isLine: true },
      { Id: '8_27', Name: 'Double Chance', col: ['1X', '12', 'X2'] },
      { Id: '6_13', Name: 'Next Goal', col: ['1', 'X', '2'] },
      { Id: '4_0', Name: 'Handicap', col: ['1', 'X', '2'] },
  ]
  },
}

export const MARKET_FILTERS_PREMATCH = {
  [sportIds.football]: FOOTBALL_MARKETS_FILTERS_PREMATCH,
  [sportIds.basketball]: BASKETBALL_MARKETS_FILTERS_PREMATCH,
  [sportIds.icehockey]: ICEHOCKEY_MARKETS_FILTERS_PREMATCH,
  [sportIds.tennis]: TENNIS_MARKETS_FILTERS_PREMATCH,
  [sportIds.handball]: HANDBALL_MARKETS_FILTERS_PREMATCH,
  [sportIds.americanFootball]: FOOTBALL_MARKETS_FILTERS_PREMATCH,
};

export const MARKET_FILTERS_LIVE = {
    [sportIds.football]: FOOTBALL_MARKETS_FILTERS_LIVE,
    [sportIds.basketball]: BASKETBALL_MARKETS_FILTERS_LIVE,
    [sportIds.icehockey]: ICEHOCKEY_MARKETS_FILTERS_LIVE,
    [sportIds.tennis]: TENNIS_MARKETS_FILTERS_LIVE,
    [sportIds.handball]: FOOTBALL_MARKETS_FILTERS_LIVE,
    [sportIds.americanFootball]: FOOTBALL_MARKETS_FILTERS_LIVE,
  };


export const MARKET_GROUP_NAMES = {
  'Final Result': 'main_markets_id_list',
  'Totals': 'totals_id_list',
  '1st Half': 'first_half_market_id_list',
  '2nd Half': 'second_half_market_id_list',
  'Home Team': 'home_team_id_list',
  'Away Team': 'away_team_id_list',
  'All Periods': 'all_periods_id_list',
  'Results': 'results_id_list',
  'Home / Away': 'home_away_id_list',
  'Score': 'score_market_id_list',
  'Corners': 'corners_market_id_list',
  'Incl. Overtime': 'incl_overtime_market_id_list',
  'Set': 'set_market_id_list',
  'Handicap': 'handicap_market_id_list'
};