export const ON_LAST_MINUTE_SPORT_SELECTED = '[APP] ON LAST MINUTE SPORT SELECTED';
export const ON_LAST_MINUTE_MAIN_MARKET_SELECTED = '[APP] ON LAST MINUTE MAIN MARKET SELECTED';
export const ON_LAST_MINUTE_REMOVE_LEAGUE = '[APP] ON LAST MINUTE REMOVE LEAGUE';
export const SET_LAST_MINUTE_SPORTS = '[APP] SET LAST MINUTE SPORTS';
export const SET_LAST_MINUTE_SPORT_EVENTS = '[APP] SET LAST MINUTE SPORT EVENTS';
export const CLEAR_LAST_MINUTE_SPORT_EVENTS = '[APP] CLEAR LAST MINUTE SPORT EVENTS';
export const ON_LAST_MINUTE_RESET_PAGE = '[APP] ON LAST MINUTE RESET PAGE';
export const UPDATE_LAST_MINUTE_EVENTS_MARKET = '[APP] UPDATE LAST MINUTE EVENTS MARKET';
export const UPDATE_LAST_MINUTE_EVENTS_STATUS = '[APP] UPDATE LAST MINUTE EVENTS STATUS';
export const SET_LAST_MINUTE_COUNT = '[APP] SET LAST MINUTE COUNT';
export const SET_LAST_MINUTE_FETCHED_ALL = '[APP] SET LAST MINUTE FETCHED ALL';
export const SET_LAST_MINUTE_CLEAR_DATA = '[APP] SET LAST MINUTE CLEAR DATA';
export const IS_LAST_MIN_AVAILABLE = '[APP] IS LAST MINUTE AVAILABLE';