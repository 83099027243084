export const SET_RESULTS_SEARCH_STARTED = '[APP] SET RESULTS SEARCH STARTED';
export const SET_RESULTS_SEARCH = '[APP] SET RESULTS SEARCH';
export const ON_RESULTS_SEARCH = '[APP] ON RESULTS SEARCH';
export const ON_RESULTS_CLEAR_SEARCH = '[APP] ON RESULTS CLEAR SEARCH';
export const ON_RESULTS_SPORT_SELECTED = '[APP] ON RESULTS SPORT SELECTED';
export const ON_RESULTS_LOCATION_SELECTED = '[APP] ON RESULTS LOCATION SELECTED';
export const SET_RESULTS_LOCATIONS_ACTIVE = '[APP] SET RESULTS LOCATIONS ACTIVE';
export const SET_RESULTS_ACTIVE = '[APP] SET RESULTS ACTIVE';
export const SET_RESULTS_LOCATION_EVENTS = '[APP] SET RESULTS LOCATION EVENTS';
export const SET_RESULTS_LOCATION_LOADING = '[APP] SET RESULTS LOCATION LOADING';
export const ON_RESULTS_REMOVE_LEAGUE = '[APP] ON RESULTS REMOVE LEAGUE';
export const CLEAR_RESULTS_LOCATION_EVENTS = '[APP] CLEAR RESULTS LOCATION EVENTS';
export const SET_RESULTS_SPORTS = '[APP] SET RESULTS SPORTS';
export const SET_RESULTS_LOCATIONS = '[APP] SET RESULTS LOCATIONS';
export const CLEAR_RESULTS_LOCATIONS = '[APP] CLEAR RESULTS LOCATIONS';
export const ON_RESULTS_NO_SEARCH_RESULTS = '[APP] ON RESULTS NO SEARCH RESULTS';
export const ON_RESULTS_RESET_PAGE = '[APP] ON RESULTS RESET PAGE';
export const SET_RESULTS_SEARCH_LOAD_MORE = '[APP] SET RESULTS SEARCH LOAD MORE';
export const SET_RESULTS_HAS_NEXT_PAGE = '[APP] SET RESULTS HAS NEXT PAGE';
export const SET_RESULTS_PARTIAL_LOCATIONS = '[APP] SET RESULTS PARTIAL LOCATION';
export const SET_RESULT_SPORTS_COUNT_OBJECT = '[APP] SET RESULTS SPORTS COUNT OBJECT';